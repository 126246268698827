import React, { useState } from "react";
import { Input, Button } from "antd";
import { Link } from "react-router-dom";
import { BASE_IMG } from "../../constants";

export default function LoginBox({
    handleLogin,
  }) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('password');

    //  show and hide password
    const handleShowPassword = () => {
      setShowPassword(showPassword === 'password' ? 'text' : 'password');
    };

    return (
      <div className="login-box-container">
        <div className="login-box-text">Login</div>
        <div className='login-social-container'>
            <div className='login-text-option'>
                <span>Using</span>
                <div className='login-option'>
                    <Button className="login-sso-container" onClick={(e) => handleLogin(e, 'google', '', '')}>
                        <img src={`${BASE_IMG}/icons8_google.svg`} />
                        <div className="login-sso-text">Google</div>
                    </Button>
                    <Button className="login-sso-container2" onClick={(e) => handleLogin(e, 'microsoft', '', '')}>
                        <img src={`${BASE_IMG}/microsoft 1.svg`} />
                    </Button>
                    <Button className="login-sso-container3" onClick={(e) => handleLogin(e, 'linkedin', '', '')}>
                        <img src={`${BASE_IMG}/linkedin 1.svg`} />
                    </Button>
                </div>
            </div>
            <div className='login-hr-tag'>
                <div><hr className='hr' /><span>or</span><hr className='hr' /></div>
                <span>Login with email</span>
            </div>
        </div>
        <div className="login-box-input-container">
          <Input className="login-box-input-container2" id="username" type="email" value={username} placeholder="Email" onChange={(e) => setUsername(e.target.value)} />
          <Input className="login-box-input-container2" id="password" type={showPassword} value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}
            suffix={ showPassword === "password" 
                ? <img src={`${BASE_IMG}/eye.svg`} alt="show-password" className="password-icon" onClick={handleShowPassword} /> 
                : <img src={`${BASE_IMG}/cross_eye.svg`} alt="hide-password" className="password-icon" onClick={handleShowPassword}/>
                }
            />
        </div>
        <div className="login-box-button-container">
          <Button
            className="login-box-button-container-button"
            style={{marginTop: '14px'}}
            onClick={(e) => handleLogin(e, "local", username, password)}
          >
            <div className="login-box-button-container-button-text">Login</div>
          </Button>
          {/* <div className="login-box-button-container-link">
            <a className="login-box-button-container-link-text">Forgot Password?</a>
          </div> */}
        </div>
        <div className="login-bottom-text-container">
            <p className="login-bottom-text-container-top">Don't have a login or password yet?</p>
            <p className="login-bottom-text-container-middle"><Link to='/register'>Sign up</Link>{" "}or Contact your company's administrator</p>
        </div>
      </div>
    );
  }