import React from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'

import { createLinksMarkup, formatToCustomTime } from '../../utils'

//TODO: Move to generic modal component
export default function NotesListComponent({ notesData, modalPopup, handleModalTrippleDot, handleButtonClick }) {

  return (
    <div className='Modal_subConatiner'>
    {notesData?.map(noteItem => (
        <Typography.Paragraph key={noteItem._id}>
            <div className='Modal_typography'>
                <p>{createLinksMarkup(noteItem.actionValue)}</p>
                <div className='Modal_leftTypography'>
                    {noteItem.updatedAt ? (
                        <p>{formatToCustomTime(noteItem.updatedAt)}</p>
                    ) : (
                        <p>{formatToCustomTime(noteItem.createdAt)}</p>
                    )}
                    <Popover
                        content={
                            <div className="profile_popup">
                                <span onClick={() => handleButtonClick(noteItem._id, noteItem.actionValue, 'edit')}>Edit</span>
                                <span onClick={() => handleButtonClick(noteItem._id, noteItem.actionValue, 'delete')}>Delete</span>
                            </div>
                        }
                        overlayClassName="profile_popover"
                        trigger="click"
                        placement="bottom"
                        open={modalPopup === noteItem}
                        onOpenChange={(visible) => handleModalTrippleDot(noteItem, visible)}>
                            <MoreOutlined className="ListComponent_moreIcon"/>
                </Popover>
            </div>
            </div>
        </Typography.Paragraph>
    ))}
</div>
  )
}
