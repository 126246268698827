import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './MobileLoginAlert.css';

function MobileLoginAlert({ showButton, handlePopupClose }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleRedirect = () => {
        navigate("/login");
    };

    const isLoginPage = location.pathname === '/login';
    const showCloseButton = !isLoginPage;

    return (
        <div className="popup">
            <div className="popup-content">
                {showCloseButton && (
                    <div className="close" onClick={handlePopupClose}>
                        <span>&times;</span>
                    </div>
                )}
                <p>You need a bigger screen to login</p>
                <p>Incompatible with the current screen size</p>
                <p>Contact us in case of any help</p>
                {showButton && (
                    <a href='https://www.raze.co.in'>
                    <button className="home-button" onClick={handleRedirect}>
                        Go to Home
                    </button>
                    </a>
                )}
            </div>
        </div>
    );
}

export default MobileLoginAlert;
