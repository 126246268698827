import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { Checkbox, message, Tabs, Pagination } from 'antd';
import './style.css';
import { fetchData } from '../../../services/HomeService';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import { handleVendorAction } from '../../../services/RemoveVendor';
import { showErrorMessage } from '../../../utils';
import ListApprovals from './ListApprovals';
import { BASE_IMG } from '../../../constants';

const { TabPane } = Tabs;

export default function VendorApprovals({ selectedParent, selectedChild, AvatarColor }) {
    const [vendorData, setVendorData] = useState([]);
    const [activeTab, setActiveTab] = useState("1");

    const [filter, setFilter] = useState('');
    const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0 });
    const [sorting, setSorting] = useState({ field: 'updatedAt', order: 'desc' });
    const [selectAll, setSelectAll] = useState(false);
    const [vendorSelections, setVendorSelections] = useState({});
    const [showRemoveModal , setShowRemoveModal] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState([]);
    const [approvalType, setApprovalType] = useState();
    const [pendingCount, setPendingCount] = useState(0);
    const [urgentCount, setUrgentCount] = useState(0);
    

    const user = useSelector((state) => state.user); 

    const fetchItems = async () => {
        if (!user.org) {
            message.warning('Please complete mandatory steps before accessing this page');
            return;
        }
        const endpoint = activeTab === "1" ? "approver" : "requester";
        const queryString = `/approvals/${user.id}/quotation/${endpoint}?page=${pagination.page}&limit=${pagination.limit}&sort=${sorting.field}-${sorting.order}&filter=${filter}`;
        const data = await fetchData(queryString);
        setVendorData(data.data);
        setPagination({ ...pagination, total: data.total });
        setApprovalType(activeTab === "1" ? "ListApprovals" : "ListApprovalsAdmin");
        const pending = data.data.filter(item => item.finalStatus === 'pending').length;
        const processing = data.data.filter(item => item.urgent == true).length;
        setPendingCount(pending);
        setUrgentCount(processing);
    };

    useEffect(() => {
        fetchItems();
    }, [pagination.page, pagination.limit, pagination.total, sorting.field, sorting.order, filter, activeTab]);

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, page, limit: pageSize });
        setSelectAll(false);
        setVendorSelections({}) 
    };

    const handleSortChange = (field, order='asc') => {
        console.log(field, order)
        setSorting({ field, order });
        setSelectAll(false);
        setVendorSelections({}) 
    };

    const handleSelectAllToggle = () => {
        let updatedVendorSelections = {};
        let selectedIds = [];

        if (selectAll) {
            // If "Select All" checkbox is checked, deselect all.
            updatedVendorSelections = {};
            selectedIds = [];
        } else {
            // If "Select All" checkbox is not checked, select all.
            vendorData.forEach(vendor => {
                updatedVendorSelections[vendor._id] = true;
                selectedIds.push(vendor._id);
            })
        }

        setVendorSelections(updatedVendorSelections);
        setSelectAll(!selectAll);
        setSelectedVendorId(selectedIds);
    };

    const openAllRemoveVendorModal =() => {
        setShowRemoveModal(true)
    }

    const closeAllRemoveVendorModal =() => {
        setShowRemoveModal(false)
    }

    const handleSelectedVendorAction = async (id, action) => {
        try {
            const { success } = await handleVendorAction(id, action);
            if (success) {
                const updatedVendorData = vendorData.filter(vendor => !id.includes(vendor._id));
                const updatedVendorSelections = { ...vendorSelections };
                id.forEach(removedId => delete updatedVendorSelections[removedId]);
                const updatedPagination = {
                    ...pagination,
                    total: pagination.total - id.length
                };
                setVendorData(updatedVendorData);
                setVendorSelections(updatedVendorSelections);
                setPagination(updatedPagination);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setShowRemoveModal(false);
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <main className="Home_container">
            <section className="Home_firstContainer" style={{padding:'24px 24px 0px 24px'}}>
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    <h1>Approvals</h1>
                </div>
                <div className='Home_tabInfo'>
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                        <TabPane tab="Assigned to me" key="1" />
                        <TabPane tab="Raised by me" key="2" />
                    </Tabs>
                    <div className='Home_tabStatus'>
                        <span>Urgent {"(" + urgentCount+ ")"}</span>
                        <span>Pending {"("+ pendingCount+ ")"}</span>
                    </div>
                </div>
            </section>
            <section className="VendorLists_adding">
                {/* {pagination.total > 1 && (
                    <section className='ListComponent_sorter'>
                        <div className='ListComponent_radio'><Checkbox onClick={handleSelectAllToggle} checked={selectAll}>Select all</Checkbox></div>
                        <div className='ListComponent_radioOption'>
                            <p className={Object.keys(vendorSelections).length > 1 ? "" : "ListComponent_disabled"} onClick={() => Object.keys(vendorSelections).length > 1 && openAllRemoveVendorModal()}>Remove</p>
                        </div>
                    </section>
                )} */}
                <section className="ListComponent_sorter">
                    <div className='ListComponent_name' style={{minWidth: '296px', justifyContent: 'space-between'}}>
                    {approvalType === 'ListApprovals' ? <p>From</p> : <p>To</p>}
                        <div className='ListComponent_sorterIcon'>
                            <CaretUpOutlined onClick={() => handleSortChange('userMeta.email', 'asc')} />
                            <CaretDownOutlined onClick={() => handleSortChange('userMeta.email', 'desc')} />
                        </div>
                    </div>

                    <div className='ListComponent_name' style={{width: "205px" , justifyContent: 'space-between'}}>
                        <p style={{paddingLeft: '45px'}}>Requested Items</p>
                        <div className='ListComponent_sorterIcon'>
                            <CaretUpOutlined onClick={() => handleSortChange('requestMeta.quoteId', 'asc')} />
                            <CaretDownOutlined onClick={() => handleSortChange('requestMeta.quoteId', 'desc')} />
                        </div>
                    </div>

                    <div className='ListComponent_name' style={{width: '158px', justifyContent: 'space-between'}}>
                        <p style={{paddingLeft: '45px'}}>Raised on</p>
                        <div className='ListComponent_sorterIcon'>
                            <CaretUpOutlined onClick={() => handleSortChange('createdAt', 'asc')} />
                            <CaretDownOutlined onClick={() => handleSortChange('createdAt', 'desc')} />
                        </div>
                    </div>
                    <div style={{ width: "159px", display: "flex",justifyContent: "center",}} >
                        <p style={{ paddingRight: "8px" }}>Status</p>{" "}
                        <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/category.svg`} alt=""/>
                    </div>
                    <p style={{ width: "146px" }}>Action</p>
                </section>
                <ListApprovals type={approvalType} vendorData={vendorData} setVendorData={setVendorData} AvatarColor={AvatarColor} />
                <div className="ListComponent_container" style={{ width: '100%' }}>
                    <Pagination
                        total={pagination.total}
                        current={pagination.page}
                        pageSize={pagination.limit}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `Total ${total} items`}
                        onChange={handlePageChange}
                        onShowSizeChange={(current, size) => {
                            setPagination({ ...pagination, page: current, limit: size });
                        }}
                    />
                </div>
            </section>

            <RequestNudgeComponent
                type={'MultipleRemoveVendor'}
                action={'MultipleRemove'}
                data={selectedVendorId}
                isVisible={showRemoveModal}
                onClose={closeAllRemoveVendorModal}
                handleNudgeSubmit={handleSelectedVendorAction}
            />
        </main>
    );
}