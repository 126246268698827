import _ from 'lodash';
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../common/Navbar";
import StatusTemplate from "../common/StatusTemplate";
import { BASE_IMG } from '../../constants';

const CTA_TEMPLATES = {
  generic_failure: {
    title: "Aaah! Something went wrong",
    subText: [
      "Brace yourself till we get the error fixed, You may also refresh the page or try again later. If you continue",
      "to see this error, raise a",
    ],
  
    supportRequestLink: "mailto:info@raze.co.in",
    supportText: "support request",
    buttonLink: "/",
    buttonText: "Go To Login",
    imageSrc: `${BASE_IMG}/failure.svg`,
  },
  grant_access_failure:{
    title: "Trouble Granting Access!",
    subText: [
      "Could not grant access to your google drive. Please try again. If you continue to see this error, raise a ",
    ],
  
    supportRequestLink: "mailto:info@raze.co.in",
    supportText: "support request",
    buttonLink: () => { window.close(); }, 
    buttonText: "Try Again",
    imageSrc: `${BASE_IMG}/failure.svg`,
  },

  grant_access_success:{
    title: "Access Granted Successfully",
    subText: [
      "You have successfully granted access to your google drive. Now you can upload and save attachments directly in your drive",
    ],
  
    supportRequestLink: "",
    supportText: "",
    buttonLink: () => { window.close(); }, 
    buttonText: "Close",
    imageSrc: `${BASE_IMG}/success.svg`,
  }
}

export default function StatusPage({mode}) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if(!mode) return <></>
  const type = queryParams.get("type");

  return (
    <>
      <Navbar/>     
      {mode === "success" && type === "gdrive" && <StatusTemplate data={CTA_TEMPLATES['grant_access_success']} />}
      {mode === "failure" && type === "gdrive" && <StatusTemplate data={CTA_TEMPLATES['grant_access_failure']} />}
      {mode === "failure" && !type && <StatusTemplate data={CTA_TEMPLATES['generic_failure']} />}
    </>
  );
}
