import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'
import CheckboxSelect from '../../common/CheckboxSelect';
import OptionSelect from '../../common/OptionSelect';

export default function ModalAdvancedFilterComponent({isVisible, onClose, onApplyFilters, filterOptions={}}) {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const handlePropertyChange = (value) => {
    setSelectedProperty(value);
    setSelectedValue(null);
  };

  const handleApplyFilters = () => {
    onApplyFilters(selectedProperty, selectedOperator, selectedValue, 'query');
    onClose();
  };

  return (
    <Modal
      title='Select Filters'
      open={isVisible}
      onCancel={onClose}
      footer={<Button key="done" type="primary" onClick={handleApplyFilters}>Apply</Button>} 
      className="Filter_modal"
    >
      <div className='Filter_content'>
        <div className='Filter_eachContent' >
          <label>Properties</label>
          <OptionSelect options={filterOptions['column']} onSelect={handlePropertyChange}  />
        </div>
        <div className='Filter_eachContent' >
          <label>Operator</label>
          <OptionSelect options={filterOptions['operator']} onSelect={setSelectedOperator}  />
        </div>
        <div className='Filter_eachContent' >
          <label>Values</label>
          {!filterOptions[selectedProperty] ? <Input placeholder="Type here"  onChange={(e) => setSelectedValue(e.target.value)} /> : <CheckboxSelect options={filterOptions[selectedProperty]}  onSelect={setSelectedValue}/>}
        </div>
      </div>
    </Modal>
      )
    }
