import React, { useState } from "react";
import ModalQuotationForm from "./ModalQuotationForm";
import { MoreOutlined } from "@ant-design/icons";
import { showErrorMessage, parseToListObj, getStatusStyle, showSuccessMessage, capitalizeFirstLetter } from "../../../utils";
import { BASE_URL, ERROR_CTA_MAP } from '../../../constants';
import { Avatar, List, Popover, Upload } from "antd";
import {deleteQuoteRelation} from './quotationService.js'
import ModalHistoryComponent from './ModalHistoryComponent'
import { showLoader } from "../../../utils";

export default function VendorQuoteComponent({ vendorData, setVendorData, AvatarColor, quoteData, setQuoteData }) {
  const [isQuotationFormVisible, setIsQuotationFormVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isSaving, setIsSaving] = useState(true) 
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadedFromList, setUploadedFromList] = useState(false)
  const [showModalHistory, setShowModalHistory] = useState(false)
  
  const closeModalHistory = () => {
    setShowModalHistory(false)
  }

  const handleTrippleDot = (item, visible) => {
    if (visible) {
        setShowPopup(item);
    } else {
        setShowPopup(null);
    }
};
  const handleOptionClick = async(item, option) => {
    if(option.linkURL == 'delete'){
      const response = await deleteQuoteRelation(item._id);
      if (response) {
        setVendorData((prevData) => prevData.filter((vendor) => vendor._id !== item._id));
        showSuccessMessage('Vendor removed successfully');
      } else {
        showErrorMessage('something went wrong');
      }
    }
    else if(option.linkURL == 'history'){
      setShowModalHistory(true)
      setActiveItem(item)
    }
    setShowPopup(null);
  }

  const openQuotationForm = (item, fromUpload) => {
    setUploadedFromList(fromUpload)
    setActiveItem(item);
    setIsQuotationFormVisible(true);
  };

  const closeQuotationForm = () => {
    setIsQuotationFormVisible(false);
  };

  const handleFileUpload = (file, item) => {
    console.log("file in upload process");
    if (!file) {
        return { success: false, errorMessage: ERROR_CTA_MAP['FILE_UPLOAD_ERROR'] };
    }
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension !== 'csv') {
        return { success: false, errorMessage: ERROR_CTA_MAP['FILE_TYPE_ERROR'] };
    }

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = (e) => {
            const csvData = e.target.result;
            // Prepare FormData
            const formData = new FormData();
            formData.append('file', file);
            // Send file to server
            fetch(`${BASE_URL}/quotations/file/${item?._id}/quote`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server responded with an error');
                }
                return response.json();
            })
            .then(serverResponse => {
                resolve({ success: true, csvData, serverResponse });
            })
            .catch(error => {
              resolve({ success: true, csvData, serverResponse: { error: error.message }});
            });
        };
        reader.readAsText(file);
    });
}
  const handleUpload = async (file, item) => {
    setUploadingFile(true);
    const { success, errorMessage, csvData , serverResponse} = await handleFileUpload(file, item);
    if (success) {
      const parsedCSVData = parseToListObj(csvData);
  
      // Update the items field within the item object
      const updatedItems = item.items.map((existingItem, index) => {
        const csvItem = parsedCSVData[index];
        
        if (csvItem) {
          return {
            ...existingItem,
            availableQuantity: csvItem["Available Quantity"],
            dueDate: csvItem["Due Date"],
            placeOfSupply: csvItem["Place of Supply"],
            quantity: csvItem["Quantity"],
            quotedPrice: csvItem["Quoted Price"],
            supplyDate: csvItem["Supply Date"],
            maxBudget: csvItem["Max Budget"],
            minBudget: csvItem["Min Budget"],
          };
        }
        return existingItem;
      });
      const updatedItem = {
        ...item,
        items: updatedItems,
      };
  
      setActiveItem(updatedItem);
      setUploadingFile(false);
      openQuotationForm(updatedItem, true);
      setIsSaving(false);
    } else {
      setUploadingFile(false);
      showErrorMessage(errorMessage);
    }
  };
  

  const truncateComment = (comment) => {
    const words = comment.split(" ");
    if (words.length > 8) {
      return words.slice(0, 8).join(" ") + "...";
    }
    return comment;
  };

  const handleSendPO = () => {
    console.log('Send PO')
  }
  const handleLinkClick = (label) => {
    console.log('Link Clicked:', label)
  }

  return (
    <section className="ListComponent_container" style={{ width: "100%" }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={vendorData}
        renderItem={(item) => (
          <List.Item onClick={() => openQuotationForm(item)}>
            <div className="ListComponent_box2 itrrr">
              <div className="ListComponent_box1" style={{ width: "218px" }}>
              <Avatar style={AvatarColor?.[0]?.style}>{item.vendorDocs[0].name.charAt(0)}</Avatar>
                <h3>
                  <div className="ListComponent_qt">
                      {item.vendorDocs && item.vendorDocs.length > 0? item.vendorDocs[0].name: ""}
                    <span>{item.vendorDocs && item.vendorDocs.length > 0? item.vendorDocs[0].business_email: ""}</span>
                  </div>
                </h3>
              </div>

              <div className="ListComponent_qs" style={{ width: "173px" }}>
                {item && item.status ? (
                  <>
                    <i style={getStatusStyle(item.status)} />
                    <span>{capitalizeFirstLetter(item.status)}</span>
                  </>
                ) : (
                  <>
                    <i style={getStatusStyle('NA')} />
                    <span>NA</span>
                  </>
                )}
              </div>

              <div className="ListComponent_qt pce" style={{ width: "147px" }}>
                 {/* TODO: make it dynamic */}
              {item?.totalPrice ? (
                  <>
                    {item?.totalPrice}
                   {item.fitsInBudget ? <span>"Fits in budget"</span> : <p></p> }
                  </>
                ) : (
                  <p>No data</p>
                )}
              </div>
                

                {item?.latestComment ?
                  <p style={{ width: "308px" }}>{truncateComment(item?.latestComment)}</p>
                :
                  <span style={{width: '330px'}} className="plm">No Notes yet</span>
                }
                
                <div className="ListComponent_box1 xdc" style={{width: '210px'}} onClick={(e) => e.stopPropagation()}>
                  {item.options && item.options["horizontalbar"]?.map((option, index) => {
                    if (option.linkURL === 'upload-quote') {
                      return (
                        <Upload
                          key={index}
                          showUploadList={false}
                          accept=".csv"
                          beforeUpload={(file) => handleUpload(file, item)}
                          disabled={item.status === 'completed'}
                        >
                          <span style={{ cursor:item.status === 'completed' ? 'not-allowed' :'pointer', color:item.status === 'completed' ? '#00000040' : '#4800FF'}}>
                            {option.label}
                          </span>
                        </Upload>
                      );
                    } else if (option.linkURL === 'send-po') {
                      return (
                        <span
                          key={index}
                          style={{ cursor:item.status !== 'completed' ? 'not-allowed' :'pointer', color:item.status !== 'completed' ? '#00000040' : '#4800FF'}}
                          onClick={() => item.status === 'completed' && handleSendPO()}
                        >
                          {option.label}
                        </span>
                      );
                    } else {
                      return (
                        <span
                          key={index}
                          style={{ cursor: 'pointer', color: '#4800FF', opacity: option.disable ? 0.5 : 1 }}
                          onClick={() => !option.disable && handleLinkClick(option.label)}
                        >
                          {option.label}
                        </span>
                      );
                    }
                  })}
                  <Popover
                         content={
                          <div className="list_popup">
                             {item.options && item.options.tripledots?.map((option) => (
                                  <span key={option.key} onClick={() => handleOptionClick(item, option)}>
                                      {option.label}
                                  </span>
                              ))}
                          </div>
                      }
                        overlayClassName="list_popover"
                        trigger="click"
                        placement="bottomLeft"
                        open={showPopup === item}
                        onOpenChange={(visible) => handleTrippleDot(item, visible)}>
                            <MoreOutlined className="ListComponent_moreIcon"/>
                    </Popover>
                </div>

            </div>
          </List.Item>
        )}
      />
       {uploadingFile && showLoader('Processing file...')}
  {
    showModalHistory && (
      <ModalHistoryComponent
        isVisible={showModalHistory}
        onClose={closeModalHistory}
        AvatarColor={AvatarColor}
        name={activeItem?.vendorDocs[0].name}
        id={activeItem?._id}
      />
    )
  }
      {isQuotationFormVisible && (
        <ModalQuotationForm
          isVisible={isQuotationFormVisible}
          onClose={closeQuotationForm}
          item={activeItem}
          quoteData={quoteData}
          handleFileUpload={handleFileUpload}
          AvatarColor={AvatarColor}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          vendorData={vendorData}
          setVendorData={setVendorData}
          setQuoteData={setQuoteData}
          uploadedFromList={uploadedFromList}
          setUploadedFromList={setUploadedFromList}
        />
      )}
    </section>
  );
}
