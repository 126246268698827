import React from 'react'
import './style.css'
import BreadcrumbComponent from '../common/BreadcrumbComponent';

import FeatureTemplate from '../template/FeatureTemplate';
import FeatureFeedbackComponent from '../template/FeatureFeedbackComponent';
import { discoverVendorsData } from '../template/Data';

export default function VendorDiscover({ selectedParent, selectedChild }) {
    return (
        <section className='Home_container'>
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    <h1>Discover Vendors</h1>
                </div>
            </section>
            <main className='VendorOnboard_adding-2'>
                <FeatureTemplate data={discoverVendorsData} />
                <FeatureFeedbackComponent name="Vendor-Discover" />
            </main>
        </section>
    )
}
