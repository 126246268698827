import { BASE_IMG } from "../../constants";

// For NotificationListComponent
export const notifications = [
  // {
  // type: 'enterprise',
  //   avatarUrl:
  //     "https://craftypixels.com/placeholder-image/800x200/29bd00/fff&text=L", // dummy placeholder image
  //   sender: "Lorem Ipsum",
  //   timestamp: "3 m ago",
  //   message: "You have a new notification",
  // },
  // {
  //   type: 'vendor',
  //     avatarUrl:
  //       "https://craftypixels.com/placeholder-image/800x200/29bd00/fff&text=L", // dummy placeholder image
  //     sender: "Lorem Ipsum",
  //     timestamp: "3 m ago",
  //     message: "You have a new Vendor notification",
  //   }
];

//For TaskListComponent
export const taskListItems = [
  // {
  //   type: 'enterprise',
  //   title: 'Title 1',
  //   description: 'Approve a request for delivery',
  //   time: '7:00 AM', 
  // },
  // {
  //   type: 'vendor',
  //   title: 'Title 1',
  //   description: 'Hi I am Vendor',
  //   time: '7:00 AM', 
  // }
];

// For InsightNudgeComponent
 export const insightsData = [
  {
    image: `${BASE_IMG}/dashboard-img/Vendor_Manangement.svg`,
    title: 'Know all about Vendor Management',
    link: 'Know-all-about-Vendor-Management-refers-to-the-process-of-effectively',
    author: 'Raze',
    duration: '0h',
  },
  {
    image: `${BASE_IMG}/dashboard-img/Travel_Expense.svg`,
    title: 'Travel and Expense Reimbursement',
    link: 'Travel-and-Expense-Reimbursement-the-practice-of-paying-employees-back-for-costs',
    author: 'Raze',
    duration: '0h',
  },
];

// For ExploreNudgeComponent
export const exploreData = [
  {
    type: 'enterprise',
    title: 'Onboard',
    description: 'Helping admin teams to seamlessly onboard in-house vendors on a single window platform...',
    image: `${BASE_IMG}/dashboard-img/explore_vid_thumb_img.png`,
    productlink: '/products',
    videoUrl: 'https://www.youtube.com/embed/crRq062hlko',
  },
  {
    type: 'vendor',
    title: 'Discover',
    description: 'Vendors are left alone in the present B2B setup and are expected a lot in terms of tech capabilities to do bu...',
    image: `${BASE_IMG}/dashboard-img/discover_vid_thumb_img.svg`,
    productlink: '/products/discover',
    videoUrl: 'https://www.youtube.com/embed/LMlCN6_vUvs',
  },
];
