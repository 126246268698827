import React from "react";
import { useSelector } from 'react-redux'; 
import "./style.css";
import { BASE_IMG } from "../../constants";

export default function WelcomeNudgeComponent() {
  const user = useSelector((state) => state.user); 
  return (
    <div className="WelcomeNudgeComponent_container">
      <div className="WelcomeNudgeComponent_subContainer" >
        <h1 style={user?.orgType ==='vendor' ? {color: '#008065'}: {}}>
          Welcome, {user ? user?.name : 'Aboard'}!!
        </h1>
        <h4>
          Thanks for choosing our platform. We are delighted to serve you.
        </h4>
      </div>
      <img src={user?.orgType === 'vendor' ? `${BASE_IMG}/dashboard-img/Welcome_vendor.svg` : `${BASE_IMG}/dashboard-img/Welcome_enterprise.svg`} alt="" />
    </div>
  );
}
