import React, { useEffect, useState } from 'react'
import { getLabelFromValue } from '../../utils';
import { Checkbox, Select } from 'antd';

const { Option } = Select;

export default function CheckboxSelect({options, onSelect}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLabelsString, setSelectedLabelsString] = useState(null);

  const handleOptionChange = (optionValue) => {
    const updatedOptions = [...selectedOptions];
    const index = updatedOptions.indexOf(optionValue);

    if (index === -1) {
      updatedOptions.push(optionValue);
    } else {
      updatedOptions.splice(index, 1);
    }
    setSelectedOptions(updatedOptions);

    const selectedLabels = updatedOptions
      .map((value) => getLabelFromValue(value, options))

    setSelectedLabelsString(selectedLabels.join(', '));
  };

  useEffect(() => {
    onSelect(selectedOptions);
  }, [selectedOptions, onSelect]);
  useEffect(()=>{
    setSelectedOptions([])
  }, [options])

  return (
    <Select placeholder='select' value={selectedLabelsString} onChange={handleOptionChange}>
    {options.map((option) => (
      <Option key={option.value} value={option.value}>
        <Checkbox.Group>
        <Checkbox
            onChange={() => handleOptionChange(option.value)}
            checked={selectedOptions.includes(option.value)}
          >
            {option.label}
          </Checkbox>

        </Checkbox.Group>
      </Option>
    ))}
  </Select>
  )
}
