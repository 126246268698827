import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import LoginComponent from "./components/login/LoginComponent";
import HomeMain from "./components/home";
import ScrollToTop from "./helpers/ScrollToTop";
import { useMediaQuery } from "react-responsive";
import MobileLoginAlert from "./components/common/MobileLoginAlert";
import { MAX_WIDTH_SMALL_LOGIN } from "./constants";
import SignUpComponent from "./components/login/SignUpComponent";
import UserVerification from "./components/user/VerificationComponent";
import StatusPage from "./components/status";
import VendorListQuotes from "./components/vendor/vendor-quotations/VendorListQuotes";

function App() {
  const isSmallScreen = useMediaQuery({ maxWidth: MAX_WIDTH_SMALL_LOGIN });

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          {!isSmallScreen ? (
            <>
              <Route exact path="/register" element={<SignUpComponent />} />
              <Route exact path="/login" element={<LoginComponent />} />
            </>
            ) : (
              <Route exact path="/login" element={<MobileLoginAlert showButton={true} />} />  
          )}
          <Route exact path="/verification" element={<UserVerification />} />
          <Route path="/home/:type?" element={<HomeMain />} />
          <Route path="/home/:type/:subtype?" element={<HomeMain />} />
          <Route path="/success" element={<StatusPage mode={'success'} />} />
          <Route path="/failure" element={<StatusPage mode={'failure'} />} />
          <Route path="/" element={<LoginComponent/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
