import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './style.css'
import Navbar from '../common/Navbar'
import { handleLoginSubmit, handleSkipLogin } from '../../services/LoginService';
import { Helmet } from "react-helmet";
import LoginBox from './LoginFormComponent';
import SelectingUser from '../user/SelectionComponent';
import { BASE_IMG, DEFAULT_REDIRECT_ROUTE } from '../../constants';
import { showErrorMessage, showLoader, showSuccessMessage } from '../../utils';

export default function LoginComponent() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [allValues, setAllValues]  = useState({
        orgType: '',
        verified: false,
        displayType: 'login',
        step: 'email',
    })
    // useEffect(() => {
    //     //skip login
    //     setIsLoading(true);
    //     handleSkipLogin()
    //     .then(({ success, data={}, message='' }) => {
                
    //         if (success) {

                
    //             if(!data.type && !(data.org_type && (data.verified === 'true')) ){
    //                 setAllValues({...allValues, displayType: 'selection', orgType: data.org_type, verified: data.verified, step: data.step});
    //                 //do nothing
    //             } else {
    //                 setAllValues({...allValues, displayType: data.type, orgType: data.org_type, verified: data.verified, step: data.step});
    //                 navigate(`${DEFAULT_REDIRECT_ROUTE}?${new URLSearchParams(data).toString()}`);
    //                 showSuccessMessage(data.message || 'Logged in successfully');
    //             }
    //         } else {
    //             showErrorMessage(message || 'Invalid email or password');
    //         }
    //         setIsLoading(false);
    //     });
    // }, []);

    const handleLogin = async (e, authType, username, password) => {
        if (authType === 'local' && (!username || !password)) {
            showErrorMessage('Enter email and password');
            return;
        }
        setIsLoading(true);
        const { success, data={} , message='' } = await handleLoginSubmit(e, authType, username, password);
        if (success) {
            navigate(`${DEFAULT_REDIRECT_ROUTE}?${new URLSearchParams(data).toString()}`);
            showSuccessMessage(data.message || 'Logged in successfully');
        } else {
            showErrorMessage(message || 'Invalid email or password');
        }
        setIsLoading(false);
    }

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setAllValues({
            ...allValues, 
            step: queryParams.get('step'),
            orgType: queryParams.get('org_type'), 
            verified: queryParams.get('verified'),
            displayType: queryParams.get('type'), 
        })
    }, []);

    return (<>
        <Helmet>
            <title>Raze- Login</title>
            <meta name="description" content="Securely log in to your account for personalized access and exclusive features. Streamline your interactions and manage your profile effortlessly" />
            <link rel="canonical" href="/login" />
        </Helmet>

        <div>
            <div className="login">
                <div className="left-container">
                    <img src={`${BASE_IMG}/login_left.svg`} height="95%" width="95%" />
                </div>
                <div className="right-container">
                    <div className='right-subContainer'>
                        <div className="app-title-logo">
                            <div className="app-logo">
                                <img src={`${BASE_IMG}/raze_logo.svg`} height="100%" width="100%" />
                            </div>
                            <div className="app-title">
                                <img src={`${BASE_IMG}/RAZE.svg`} />
                            </div>
                        </div>
                        <div className="app-motto-text">Redefining expense settlements, we make "the Boring" work</div>
                        {allValues.displayType === 'selection' ? (
                            <SelectingUser orgType={allValues.orgType} verified={allValues.verified} step={allValues.step}/>
                        ) : (
                            <LoginBox
                                handleLogin={handleLogin}
                            />
                        )}

                        <p className="login-bottom-text-container-bottom">&copy; Copyright 2023 Raze Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
            <Navbar />
            {isLoading && showLoader("Logging")} 
        </div>
    </>
    );
}

