import { Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { fetchData, postData } from "../../../services/HomeService";
import _ from "lodash";
import PaywallCard from "../../common/PaywallCard";
import { showErrorMessage, showSuccessMessage, sortPlansAndOptions } from "../../../utils";
import RequestNudgeComponent from "../../nudge/RequestNudgeComponent";
import { useSelector } from "react-redux";
import { BASE_IMG } from "../../../constants";


export default function ModalPayWallComponent({
  isVisible,
  onClose,
  selectedKey,
}) {
  const [isAnnual, setIsAnnual] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(
    selectedKey ? [selectedKey] : []
  );

  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [submitModalRequestData, setSubmitModalRequestData] = useState('modal-paywall');
  
  const userData = useSelector((state) => state.user); 

  const handleNudgeSubmit = async (data, onClose) => {
    const { name = '', orgName, email = '' } = userData || {};
    const company = orgName || 'NA';
    const requestData = { name, company, email, comment: data };

    try {
      const response = await postData("/notifications/contact-us", requestData);
      if (response.success) {
        showSuccessMessage("Request successfully submitted!!");
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      onClose();
    }
  };

  const handleSubmit = (selectedPlan, selectedKeys=[]) => {
    const data = JSON.stringify({
      name: selectedPlan.name,
      mode: selectedPlan.mode,
      type: selectedPlan.type,
      selectedOptions: _.map(selectedPlan.options.filter((option) => (selectedPlan.topPlan || selectedKeys && selectedKeys.includes(option.key))), 'description'),
    })
    setIsSubmitModalVisible(true);
    setSubmitModalRequestData(data)
  };

  const closeSubmitPlanModal = () => {
    setIsSubmitModalVisible(false);
  };

  const initializeSelectedKeys = () => {
    if (selectedKeys.length === 1) {
      // Case 2: One element
      setSelectedKeys([selectedKey]);
    } else if (selectedKeys.length === 0) {
      // Case 1: No element
      setSelectedKeys([plans[0].options[0].key]);
    } else {
      // Case 3: ALL elements
      // TODO : Control over how many options will display 
      setSelectedKeys(plans[0].options.map((option) => option.key));
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      const response = await fetchData(
        `/subscriptions/pricing-enterprise?mode=${isAnnual ? "y" : "m"}`
      );
      //sorting in original response, it occur late display of sorted data
      const sortedPlans = sortPlansAndOptions(response.data, selectedKey);
      setPlans(sortedPlans);
    };
    fetchItems();
  }, [isAnnual, selectedKey]);

  useEffect(() => {
    if (isVisible) {
      initializeSelectedKeys();
    }
  }, [isVisible, selectedKey, plans]);

  const basicPlan = plans.find((item) => !item.topPlan);
  const premiumPlan = plans.find((item) => item.topPlan);

  if (!basicPlan || !premiumPlan) {
    return null;
  }

  const handleCheckboxChange = (optionKey) => {
    if (selectedKeys.includes(optionKey)) {
      setSelectedKeys(selectedKeys.filter((key) => key !== optionKey));
    } else {
      setSelectedKeys([...selectedKeys, optionKey]);
    }
  };

  const basicCost = selectedKeys.reduce((acc, key) => {
    const selectedOption = basicPlan.options.find(
      (option) => option.key === key
    );
    return acc + (selectedOption ? selectedOption.cost : 0);
  }, 0);

  const premiumCost = premiumPlan.options.reduce(
    (acc, option) => acc + option.cost,
    0
  );

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className="Paywall_modal"
    >
      <div className="Pw_layout">
        <div className="Pw_top">
          <h3>Upgrade to Unlock</h3>
          <img src={`${BASE_IMG}/paywall.svg`} alt="" />
        </div>
        <div className="Pw_bottom">
          <div className="Pw_switchGroup">
            <span>Choose your plan</span>
            <div className="Pw_switch">
              <span style={{ color: isAnnual ? "#8C8C8C" : "#434343" }}>
                Monthly
              </span>
              <Switch
                checked={isAnnual}
                onChange={() => setIsAnnual(!isAnnual)}
              />
              <span style={{ color: isAnnual ? "#434343" : "#8C8C8C" }}>
                Annual
              </span>
            </div>
          </div>
          <div className="Pw_cardGroup">
            <PaywallCard
              plan={basicPlan}
              isAnnual={isAnnual}
              totalCost={basicCost}
              selectedKeys={selectedKeys}
              handleCheckboxChange={handleCheckboxChange}
              handleUnlockButtonClick={handleSubmit}
            />
            <PaywallCard
              plan={premiumPlan}
              isAnnual={isAnnual}
              totalCost={premiumCost}
              selectedKeys={premiumPlan.options.map((option) => option.key)}
              handleCheckboxChange={handleCheckboxChange}
              handleUnlockButtonClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <RequestNudgeComponent
        type={'subscribe'}
        data={submitModalRequestData}
        isVisible={isSubmitModalVisible}
        onClose={closeSubmitPlanModal} 
        handleNudgeSubmit={handleNudgeSubmit}
      />
    </Modal>
  );
}
