import { Button, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import './ModalRejectComponent.css';

export default function ModalRejectComponent({ type, isVisible, onClose, action, handleAction }) {
    const [reason, setReason] = useState('');
    const CTA_MAP = {
        'reject': {
            ButtonText: 'Reject',
            background: '#FF4D4F',
            border: '1px solid #FF4D4F'
        }
    };

    const maxCharacterCount = 50;

    return (
        <Modal
            title='Reason for rejection'
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            className="Quotes_modal Modal_qt"
        >
            <div className='Approval_Reject'>
                <TextArea
                    rows={3}
                    placeholder="Type or paste here"
                    value={reason}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= maxCharacterCount) {
                            setReason(inputValue);
                        }
                    }}
                />
                <div className='Approval_Reject_Mend'>
                    <span><span style={{ color: '#FF4D4F' }}>*</span>This is a mandatory field</span>
                    <span>{reason.length}/{maxCharacterCount}</span>
                </div>
            </div>
            <div className="Quotes_footer">
                <Button
                    key="done"
                    type="primary"
                    style={{ backgroundColor: CTA_MAP[type].background, border: CTA_MAP[type].border }}
                    onClick={() => handleAction(action, type, reason)}
                    disabled={reason.length === 0 || reason.length > maxCharacterCount}
                >
                    {CTA_MAP[type].ButtonText}
                </Button>
            </div>
        </Modal>
    );
}
