import { CheckCircleFilled } from "@ant-design/icons";
import { Progress, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./ProgressBarComponent.css";
import { useSelector } from "react-redux";

export default function ProgressBarComponent({ vendorData, vendorListCount}) {
  const user = useSelector((state) => state.user);

 const calculateProgress = (vendorData, vendorListCount) => {
    let progressPercentage = (vendorData - vendorListCount) * 100;
    progressPercentage = Math.max(progressPercentage, 0);
    progressPercentage = Math.min(progressPercentage, 100);
    return Math.floor(progressPercentage);
};

  const modifiedProgressData = [
    {
      id: 1,
      type: 'enterprise',
      title: "Update Account Information",
      progress: user && user?.org ? 100 : 0,
      completedText: " completed",
      link: "/home/account-info",
      progressText: user && user?.org ? "100%" : "0%",
    },
    {
      id: 2,
      type: 'enterprise',
      title: ((vendorData > 0 && vendorData <= vendorListCount[0]?.minVendorsListCount) && 'Add More Vendors') || (vendorData === 0 && 'Add Vendors'),
      progress: calculateProgress(vendorData, vendorListCount[0]?.minVendorsListCount),
      completedText: " completed",
      link: "/home/vendor-add",
      progressText: `${calculateProgress(vendorData, vendorListCount[0]?.minVendorsListCount)}%`
    },
    {
      id: 1,
      type: 'vendor',
      title: "Add Your Account Information",
      progress: user && user?.org ? 100 : 0,
      completedText: " completed",
      link: "/home/account-info",
      progressText: user && user?.org ? "100%" : "0%",
    },
    {
      id: 2,
      type: 'vendor',
      title: "Create your business profile",
      progress: 0, // TODO - Implement the progress check when vendor business profile is implemented
      completedText: " completed",
      link: "",
      progressText: "0%",
    },
  ];

  const calculateAverageProgress = (progressData) => {
    const totalProgress = progressData.reduce(
      (total, step) => total + step.progress,
      0
    );
    return totalProgress / progressData.length;
  };
  const filteredProgressData = modifiedProgressData.filter(item => item.type === user?.orgType);

  const averageProgress = calculateAverageProgress(filteredProgressData);

  return (
    <div className="ProgressBarComponent_container">
      <h5 style={user?.orgType ==='vendor' ? {color: '#008065'}: {}}>Complete your profile</h5>
      <div className="ProgressBarComponent_subContainer">
        <div className="ProgressBarComponent_circle">
          <Space wrap>
            <Progress
              type="circle"
              percent={averageProgress}
              width={80}
              strokeWidth={6}
              strokeColor={user?.orgType === 'vendor' ? '#008065' : '#4800FF'}
            />
          </Space>
        </div>
        <div className="ProgressBarComponent_progressContainer">
            <p>Finish the following mandatory steps:</p>
          <div className="ProgressBarComponent_content">
            {filteredProgressData.map((step, index) => (
              <>
                <div className="ProgressBarComponent_text" key={index}>
                  <div className={`ProgressBarComponent_subText ${user?.orgType === 'vendor' && 'colorG'}`}>
                    {step.progress === 100 ? (
                      <CheckCircleFilled />
                    ) : (
                      <span>{step.id}</span>
                    )}
                    <div style={{textAlign: 'start'}}>
                    <Link to={step.link}>{step.title}</Link>
                    </div>
                  </div>
                  <p>
                    {step.progressText}
                    {step.completedText}
                  </p>
                </div>
                {index !== filteredProgressData.length - 1 && (
                  <div className="ProgressBarComponent_hr" ><hr/></div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
