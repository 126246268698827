import {  BASE_URL } from '../constants/index';

export const fetchData = async (ENDPOINT_URL) => {
    try {
        if (!ENDPOINT_URL) return null;
        const response = await fetch(`${BASE_URL}${ENDPOINT_URL}`, {
            credentials: 'include',
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching items:', error);
        return null;
    }
};

export const postData = async (ENDPOINT_URL, requestData) => {
    try {
      if (!ENDPOINT_URL) return null;
        const response = await fetch(`${BASE_URL}${ENDPOINT_URL}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in POST request:', error);
    }
  };

  export const putData = async (ENDPOINT_URL, requestData) => {
    try {
      if (!ENDPOINT_URL) return null;
        const response = await fetch(`${BASE_URL}${ENDPOINT_URL}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in PUT request:', error);
    }
  };

  export const deleteData = async (ENDPOINT_URL, requestData) => {
    try {
      if (!ENDPOINT_URL) return null;
        const response = await fetch(`${BASE_URL}${ENDPOINT_URL}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in DELETE request:', error);
    }
  };