import React, { useState } from "react";
import "./style.css";
import { Button } from "antd";
import ModalPayWallComponent from "../vendor/vendor-list/ModalPayWallComponent";
import { useSelector } from "react-redux";

export default function SubscribeNudgeComponent() {
  const [isPayWallModalVisible, setIsPayWallModalVisible] = useState(false);
  const userData = useSelector((state) => state.user); 

  const ClosePayWallModal = () => {
    setIsPayWallModalVisible(false);
  };

  const openPayWallModal = () => {
    setIsPayWallModalVisible(true);
  };
  return (
    <>
      <div className="SubscribeNudgeComponent_container" style={userData?.orgType ==='vendor' ? {background: '#E0FFF9'}: {}}>
        <div className="SubscribeNudgeComponent_text">
          <h5 style={userData?.orgType ==='vendor' ? {color: '#008065'}:{}}>
            {userData?.orgType === 'enterprise' ? 'Upgrade to Premium': 'Upgrade to Pro'}
          </h5>
          <p>Go next level. Go beyond.</p>
        </div>
        <Button style={userData?.orgType ==='vendor' ? {background: '#008065', border:'1px solid #008065'}: {}} onClick={openPayWallModal}>See Plans</Button>{" "}
      </div>
      <ModalPayWallComponent
        isVisible={isPayWallModalVisible}
        onClose={ClosePayWallModal}
      />
    </>
  );
}
