import { showErrorMessage, showSuccessMessage } from "../utils";
import { deleteData } from "./HomeService";

export const handleVendorAction = async (id, action) => {
    if (!id || !action) return { success: false };

    let response;
    switch (action) {
        case 'remove':
        case 'MultipleRemove':
            const payload = action === 'remove' ? { "ids": [id] } : { "ids": id };
            response = await deleteData(`/relations/disable-many`, payload);
            if (response.success) {
                showSuccessMessage("Removed successfully");
                return { success: true };
            } else {
                showErrorMessage(response.message);
            }
            break;
        default:
            console.error('Invalid action:', action);
            break;
    }
    return { success: false };
};