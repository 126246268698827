import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { message , Button} from 'antd';
import './style.css';
import { fetchData } from '../../../services/HomeService';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { BASE_IMG } from '../../../constants';
import ListQuotes from './ListQuotes';
import ModalQuotesComponent from "../vendor-list/ModalQuotesComponent";

export default function VendorQuotations({ selectedParent, selectedChild, AvatarColor, ComponentConfig }) {
    const [vendorData, setVendorData] = useState([]);
    const [showNewQuoteModal, setShowNewQuoteModal] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0 });
    const [sorting, setSorting] = useState({ field: 'updatedAt', order: 'desc' });
    const [trigger, setTrigger] = useState(false)

    const user = useSelector((state) => state.user); 

    const fetchItems = async () => {
        if (!user.org) {
            message.warning('Please complete mandatory steps before accessing this page');
            return;
        }
        const queryString = `/quotations/source/${user.org}?page=${pagination.page}&limit=${pagination.limit}&sort=${sorting.field}-${sorting.order}`;
        const data = await fetchData(queryString);
        setVendorData(data.data);
        setPagination({ ...pagination, total: data.total });
    };

    useEffect(() => {
        fetchItems();
    }, [pagination.page, pagination.limit, sorting.field, sorting.order, trigger]);

    const onNewQuote = (value) => {
        setShowNewQuoteModal(true);
    }

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, page, limit: pageSize });
    };

    const handleSortChange = (field, order='asc') => {
        setSorting({ field, order });
    };



    return (
        <main className="Home_container">
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
               <div className="Home_heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <h1>Quotations</h1>
                <div className="Home_headingButton">
                    {
                        ComponentConfig && ComponentConfig[0] &&
                        <Button type="primary" onClick={onNewQuote}>
                            {ComponentConfig[0]?.label }
                        </Button>
                    }
                </div>
            </div>
            </section>
            <section className="VendorLists_adding">
            <section className="ListComponent_sorter">
          <div className="ListComponent_name" style={{ width: "296px" }}>
            <p>Quotation Title</p>
            <div className="ListComponent_sorterIcon">
              <CaretUpOutlined onClick={() => handleSortChange("title", "asc")}/>
              <CaretDownOutlined onClick={() => handleSortChange("title", "desc")}/>
            </div>
          </div>
          <p style={{ width: "205px" }}>Requested Items</p>
          <p style={{ width: "259px" }}>Vendor</p>
          <div style={{ width: "159px", display: "flex",justifyContent: "center",}} >
            <p style={{ paddingRight: "8px" }}>Status</p>{" "}
            <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/category.svg`} alt=""/>
          </div>
          <p style={{ width: "191px" }}>Requested on</p>
        </section>
                <ListQuotes vendorData={vendorData} AvatarColor={AvatarColor} setTrigger={setTrigger} />
                <div className="ListComponent_container" style={{ width: '100%' }}>
                    <Pagination
                        total={pagination.total}
                        current={pagination.page}
                        pageSize={pagination.limit}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `Total ${total} items`}
                        onChange={handlePageChange}
                        onShowSizeChange={(current, size) => {
                            setPagination({ ...pagination, page: current, limit: size });
                        }}
                    />
                </div>
            </section>
            {
                showNewQuoteModal && (
                <ModalQuotesComponent
                    isVisible={showNewQuoteModal}
                    onClose={()=>setShowNewQuoteModal(false)}
                    type={'newQuote'}
                    setTrigger={setTrigger}
                />
                )
            }
        </main>
    );
}
