import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import NotesListComponent from '../NotesListComponent';

export default function ModalNotesComponent({ modalData, setDailogBoxVisible, handleModalAction, isModalVisible, setIsModalVisible }) {

    const [modalPopup, setModalPopup] = useState(null);

    const closeNotesModal = () => {
        setIsModalVisible(false);
    };

    const handleModalTrippleDot = (item, visible) => {
        if (visible) {
            setModalPopup(item);
        } else {
            setModalPopup(null);
        }
    };

    const handleNewNoteClick = () => {
        handleModalAction('', '', 'add');
        setDailogBoxVisible(true);
    };

    const handleButtonClick = (id, value, actionType) => {
        setModalPopup(null);
        setDailogBoxVisible(true); 
        handleModalAction(id, value, actionType);
    }

    return (
        <Modal
            open={isModalVisible}
            onOk={closeNotesModal}
            onCancel={closeNotesModal}
            footer={null}
            style={{ maxWidth: '1152px' }}>
            <div className='Modal_container'>
                <div className='Modal_notes'>
                    <h4>Vendor Related Notes</h4>
                    <p>Only you will be able to see the notes</p>
                </div>
                <NotesListComponent
                    notesData={modalData}
                    modalPopup={modalPopup}
                    handleModalTrippleDot={handleModalTrippleDot}
                    handleButtonClick={handleButtonClick}
                />
                <Button type="primary" className='ModalList_Button' onClick={handleNewNoteClick}>
                    + New Notes
                </Button>
            </div>
        </Modal>
    )
}
