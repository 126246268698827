import React, { useState } from "react";
import { Input, Button, Checkbox } from "antd";
import Navbar from "../common/Navbar";
import { handleLoginSubmit, handleSignUpSubmit } from "../../services/LoginService";
import { Link, useNavigate } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "../../utils";
import { BASE_IMG } from "../../constants";

export default function SignUpComponent() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const handleSignUp = async ( username, password) => {
    if (!username || !password) {
        return;
    }
    const name = username.split('@')[0];
    const { success, message='' } = await handleSignUpSubmit( name, username, password);
    if (success) {
        navigate('/login');
        showSuccessMessage(message || 'Signed up successfully'); 
    }else {
      showErrorMessage(message || 'Something went wrong');
    }
}
 
  //  show and hide password
  const handleShowPassword = () => {
    setShowPassword(showPassword === "password" ? "text" : "password");
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(!e.target.checked);
  };

  return (
    <>
      <div>
        <div className="login">
          <div className="left-container">
            <img src={`${BASE_IMG}/login_left.svg`} height="95%" width="95%" />
          </div>
          <div className="right-container">
            <div className="right-subContainer">
              <div className="app-title-logo">
                <div className="app-logo">
                  <img src={`${BASE_IMG}/raze_logo.svg`} height="100%" width="100%" />
                </div>
                <div className="app-title">
                  <img src={`${BASE_IMG}/RAZE.svg`} />
                </div>
              </div>
              <div className="app-motto-text">
                Redefining expense settlements, we make "the Boring" work
              </div>

              <div className="register-box-container">
                <div className="login-box-text">Sign up</div>
                <div className="login-social-container">
                  <div className="login-text-option">
                    <span>Using</span>
                    <div className="login-option">
                      <Button className="login-sso-container" onClick={(e) => handleLoginSubmit(e, 'google', '', '')}>
                        <img src={`${BASE_IMG}/icons8_google.svg`} />
                        <div className="login-sso-text">Google</div>
                      </Button>
                      <Button className="login-sso-container2" onClick={(e) => handleLoginSubmit(e, 'microsoft', '', '')}>
                        <img src={`${BASE_IMG}/microsoft 1.svg`} />
                      </Button>
                      <Button className="login-sso-container3" onClick={(e) => handleLoginSubmit(e, 'linkedin', '', '')}>
                        <img src={`${BASE_IMG}/linkedin 1.svg`} />
                      </Button>
                    </div>
                  </div>
                  <div className="login-hr-tag">
                    <div>
                      <hr className="hr" />
                      <span>or</span>
                      <hr className="hr" />
                    </div>
                    <span>Sign up with email</span>
                  </div>
                </div>

                <div className="login-box-input-container">
                  <Input
                    className="login-box-input-container2"
                    id="username"
                    type="email"
                    value={username}
                    placeholder="Email"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input
                    className="login-box-input-container2"
                    id="password"
                    type={showPassword}
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    suffix={
                      showPassword === "password" ? (
                        <img
                          src={`${BASE_IMG}/eye.svg`}
                          alt="show-password"
                          className="password-icon"
                          onClick={handleShowPassword}
                        />
                      ) : (
                        <img
                          src={`${BASE_IMG}/cross_eye.svg`}
                          alt="hide-password"
                          className="password-icon"
                          onClick={handleShowPassword}
                        />
                      )
                    }
                  />
                </div>
                <div className="login-box-button-container">
                  <Checkbox onChange={handleCheckboxChange}>
                    <p>
                      Creating an account means you agree to our{" "}
                      <span>Terms of</span>
                    </p>{" "}
                    <p>
                      <span>Service</span> and <span>Privacy Policy</span>
                    </p>
                  </Checkbox>
                  <Button
                    disabled={isCheckboxChecked}
                    className={`login-box-button-container-button ${
                      isCheckboxChecked ? "disabled-button" : ""
                    }`}
                    onClick={(e) => handleSignUp( username, password)}
                  >
                    <div className="login-box-button-container-button-text">
                      Create account
                    </div>{" "}
                  </Button>
                </div>
                <p>Already have an account?{" "}<Link to='/login'>Login</Link></p>
                <p className="login-bottom-text-container-bottom">
                  &copy; Copyright 2023 Raze Inc. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Navbar />
      </div>
    </>
  );
}
