import { BASE_IMG } from "../../constants";

export const rankVendorsData = {
    imgSrc: `${BASE_IMG}/Feature_1.svg`,
    heading: "Get your business up with the best vendors",
    subHeading: "Want to get associated with the best rated vendors?",
    footer: "This feature might just do the wonders to get you there!",
    list: [
        "Who can deliver on promises and best in their segment",
        "Have high availability score and provide quality services",
        "Are genuine, verified and have a good platform score",
    ],
};

export const discoverVendorsData = {
    imgSrc: `${BASE_IMG}/Feature_2.svg`,
    heading: "Discover vendors that will fit you right",
    footer: "This feature might just do the wonders to get you there!",
    list: [
        "Find new vendors who can provide desired services",
        "See their portfolio and business operations parameters",
        "Matchmaking of potential vendors based on business needs to reduce time efforts of vendor searching",
    ],
};


