import { BASE_URL } from '../../../constants/index.js';

export const getComments = (id) => {
    return fetch(`${BASE_URL}/quotations/comment/${id}`, {
        credentials: 'include',
        method: 'GET',
    })
    .then(response => response.json());
}

export const getCommentsApproval = (id) => {
    return fetch(`${BASE_URL}/approvals/comments/${id}`, {
        credentials: 'include',
        method: 'GET',
    })
    .then(response => response.json());
}

export const getHistory = (id) => {
    return fetch(`${BASE_URL}/quotations/history/${id}`, {
        credentials: 'include',
        method: 'GET',
    })
    .then(response => response.json());
}

export const getStatus = (id) => {
    return fetch(`${BASE_URL}/quotations/quoteitems/${id}`, {
        credentials: 'include',
        method: 'GET',
    })
    .then(response => response.json());
}

export const getFiles = (url) => {
    return fetch(`${BASE_URL}${url}`, {
        credentials: 'include',
        method: 'GET',
    })
    .then(response => response.json());
}

export const postComment = (id, newNote) => {
    return fetch(`${BASE_URL}/quotations/comment/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(newNote),
    })
    .then(response => response.json());
}

export const postCommentApproval = (id, newNote) => {
    return fetch(`${BASE_URL}/approvals/comments/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(newNote),
    })
    .then(response => response.json());
}

export const postItems = async (id, newItems) => {
    try {
        const response = await fetch(`${BASE_URL}/quotations/items/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ items: newItems }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return { ...data, ok: true };
    } catch (error) {
        console.error("Error in postItems:", error);
        return { ok: false, error: error.message };
    }
};
export const fetchQuoteWithQuoteId = async (quoteId) => {
    try {
        const response = await fetch(`${BASE_URL}/quotations/${quoteId}`, {
            credentials: 'include',
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching items:', error);
        return null;
    }
}
export const deleteQuoteRelation = async (relationId) => {
    try {
        const response = await fetch(`${BASE_URL}/quotations/quote-relation/${relationId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting quote relation:', error);
        return null;
    }
}