import React, { useState } from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { HOMEPAGE_REDIRECT_ROUTE } from '../../constants';
import { PlayCircleFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export default function ExploreNudgeComponent({exploreData}) {
 const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
 const [currentVideoUrl, setCurrentVideoUrl] = useState('');
 const userData = useSelector((state) => state.user); 

  const handleImageClick = (videoUrl, image) => {
    setIsVideoModalVisible(true);
    setCurrentVideoUrl(videoUrl);
  };

  const handleCancel = () => {
    setIsVideoModalVisible(false);
  };

  const filteredExploreData = exploreData.filter(item => item.type === userData?.orgType);

  return (
    <div className="ExploreNudgeComponent_container">
      <h5 style={userData?.orgType ==='vendor' ? {color: '#008065'}: {}}>Explore our products</h5>
      {filteredExploreData.map((item, index) => (
          <div className="ExploreNudgeComponent_card" key={index}>
            <Link className='ExploreNudgeComponent_text' to={`${HOMEPAGE_REDIRECT_ROUTE}${item.productlink}`} target='_blank'>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </Link>
            <div className='ExploreNudgeComponent_img' onClick={() => handleImageClick(item.videoUrl)}>
              <PlayCircleFilled />
              <img src={item.image} alt='product_video' />
            </div>
          </div>
      ))}
      <Modal
        className="ExploreNudgeComponent_videoModal"
        open={isVideoModalVisible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <iframe
          src={currentVideoUrl}
          title="Demo Video"
          style={{ border: "0px" }}
          allowFullScreen
        />
      </Modal>
    </div>
  );
}
