import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => action.payload,
    updateUser: (state, action) => { 
      for(const key of Object.keys(action.payload)) {
        if(['id', 'email', 'name'].includes(key)) continue
        else state[key] = action.payload[key]
      }
    },
    clearUser: (state) => null,
  },
});

export const { setUser, updateUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
