import { FileDoneOutlined } from '@ant-design/icons'
import { Avatar, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';

export default function TaskListComponent({taskListItems }) {
  const userData = useSelector((state) => state.user); 
  const filteredTaskListItems = taskListItems.filter(item => item.type === userData?.orgType);
  
  if (filteredTaskListItems.length === 0) {
    return (
      <div className="TaskListsComponent_container">
        <h4 style={userData?.orgType ==='vendor'? {color: '#008065'}: {}}>Your tasks</h4>
        <div className="TaskListsComponent_subContainer" style={{gap: '0'}}>
          <div
            className="NotificationListComponent_subContainer"
            style={{ alignItems: "center", width: "100%"}}
          >
            <p>You have no tasks</p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="TaskListsComponent_container">
      <h4 style={userData?.orgType ==='vendor'? {color: '#008065'}: {}}>Your tasks</h4>
      <div className="TaskListsComponent_subContainer">
        <div className="TaskListsComponent_typography">
          {filteredTaskListItems.map((item, index) => (
            <Typography.Paragraph key={index}>
              <div>  
                <Avatar className={userData?.orgType ==='vendor' && 'colorG'} size={45} icon={<FileDoneOutlined />} /> {/* TODO: Replace the icon with actual one */}
              </div>
              <div className='TaskListsComponent_text'>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
              </div>
              <div className='TaskListsComponent_time'>
              <p>{item.time}</p> 
              </div>
            </Typography.Paragraph>
          ))}
        </div>
      </div>
    </div>
  )
}
