import React, { useState } from 'react';
import { DownloadOutlined, ExclamationCircleFilled, FileDoneOutlined, LoadingOutlined, SolutionOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Spin, Upload } from 'antd';

const textProcessingSpin = <LoadingOutlined spin />;
const textProcessingMessage = <span style={{ color: '#FFFFFF' }}>Processing entries</span>;

export default function StepByStepAdditionComponent({ onCSVUpload, handleDownloadClick, handleFileUpload }) {
    const [isFileDownloaded, setIsFileDownloaded] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [csvData, setCSVData] = useState();

    const handleDownload = async () => {
        const { success } = await handleDownloadClick();
        if (success) {
            setIsFileDownloaded(true);
        } else {
            alert('Encountered error while downloading the file');
        }
    };

    const handleButtonClick = () => {
        setIsProcessing(true);
        setTimeout(() => {
            onCSVUpload(csvData);
        }, 1000);
    };

    const handleUpload = async (file) => {
        const { success, errorMessage, csvData } = await handleFileUpload(file);
        if (success) {
            setCSVData(csvData);
            setIsFileUploaded(true);
        } else {
            setInvalidFileType(false);
            setErrorMessage(errorMessage);
        }
    }

    return (
        <section className="StepByStepAddition_container">
            <div className="StepByStepAddition_subContainer">
                <div className="StepByStepAddition_icon">
                    <DownloadOutlined />
                </div>
                <div className="StepByStepAddition_details">
                    <h2>Step 1: Download the csv file</h2>
                    <p>Download the csv file on your computer and open it to add details.</p>
                    <Button
                        type="primary"
                        onClick={handleDownload}
                        style={{ background: isFileDownloaded ? '#52C41A' : '#4800FF' }}
                    >
                        Download csv format
                    </Button>
                </div>
            </div>
            <div className="StepByStepAddition_subContainer">
                <div className="StepByStepAddition_icon">
                    <SolutionOutlined />
                </div>
                <div className="StepByStepAddition_details">
                    <h2>Step 2: Add Details</h2>
                    <p>Fill the respective columns, do not change the order or name of the fields. Save the file before uploading.</p>
                </div>
            </div>
            <div className="StepByStepAddition_subContainer">
                <div className="StepByStepAddition_icon">
                    <UploadOutlined />
                </div>
                <div className="StepByStepAddition_details">
                    <h2>Step 3: Upload the filled file</h2>
                    <p>Upload the filled file using the upload button.</p>
                    <Upload
                        showUploadList={true}
                        accept=".csv"
                        beforeUpload={handleUpload}
                        disabled={!isFileDownloaded}
                    >
                        <Button
                            type="primary"
                            style={{ background: isFileUploaded ? '#52C41A' : '#4800FF', color: '#FFFFFF' }}
                            disabled={!isFileDownloaded}
                        >
                            Upload filled csv file
                        </Button>
                    </Upload>
                    {invalidFileType && (
                        <Alert
                            className="BulkUpload_alert"
                            message={errorMessage}
                            type="error"
                            icon={<div className="BulkUpload_icon"><ExclamationCircleFilled style={{ color: '#FAAD14' }} /></div>}
                            showIcon
                            closable
                            style={{ marginTop: '16px' }}
                        />
                    )}
                </div>
            </div>
            {isProcessing && (
                <div className="BulkUpload_loading">
                    <Spin indicator={textProcessingSpin} tip={textProcessingMessage} />
                </div>
            )}
            <div className="StepByStepAddition_subContainer">
                <div className="StepByStepAddition_icon">
                    <FileDoneOutlined />
                </div>
                <div className="StepByStepAddition_details">
                    <h2>Step 4: Review entries</h2>
                    <p>Your entries should be visible as entries. Review and save them.</p>
                    <Button
                        type="primary"
                        onClick={handleButtonClick}
                        style={{ background: '#4800FF', color: '#FFF', padding: '0 39px' }}
                        disabled={!isFileUploaded || isProcessing} >
                        View entries
                    </Button>
                </div>
            </div>
        </section>
    );
}