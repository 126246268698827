import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';

import { updateUser } from "../../helpers/userSlice";
import { putData } from "../../services/HomeService";
import { showErrorMessage } from "../../utils";


export default function SelectionComponent({orgType, verified, step}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user); 


  const [activeButton, setActiveButton] = useState(orgType);

  const handleOptionClick = (option) => {
    setActiveButton(option);
  };

  useEffect(() => {
    if (orgType) {
      navigate(`/verification?userType=${orgType}&userVerified=${verified}&userStep=${step}`);
    }
  }, [orgType]);


  const handleButtonClick = async () => {
    if (activeButton) {
      const id = _.get(userData, 'id', '');
      const email = _.get(userData, 'email', '');
      if(!id|| !email) return
      //TODO: Add logic to avoid this call in case of local login
      //TODO: Mandatory update if veriried is true already
      const response = await putData(`/users/${id}`, { orgType : activeButton, email});
      if (response.success) {
        dispatch(updateUser({orgType: activeButton}));
        navigate(`/verification?userType=${activeButton}&userVerified=${verified}&userStep=${step}`);
      } else {
        showErrorMessage(response.message);
      }
    } else {
      alert("Please select where you belong")
    }
  };

  return (
    <div className='login-welcome-container'>
      <h1>Welcome!</h1>
      <h3>Which one are you?</h3>
      <div className='login-choose-button'>
        <Button className={activeButton === 'vendor' ? 'active' : ''} onClick={() => handleOptionClick('vendor')} >
          <CheckOutlined />Vendor
        </Button>
        <Button className={activeButton === 'enterprise' ? 'active' : ''} onClick={() => handleOptionClick('enterprise')} >
          <CheckOutlined />Enterprise
        </Button>
      </div>
      <div className='login-submit-button'>
        <Button className={activeButton ? 'active' : 'disabled'} disabled={!activeButton} onClick={handleButtonClick} >
          Enter
        </Button>
      </div>
    </div>
  );
}
