import React from 'react'
import './style.css';
import { Button } from 'antd';
import { BASE_IMG } from '../../../constants';
export default function AddManualComponent({ onManualUpload }) {
    const handleButtonClick = () => {
        onManualUpload();
    };
    return (
        <section className='AddManual_container'>
            <div className='VendorListAdding_img'>
                <img src={`${BASE_IMG}/dashboard-img/Paper.svg`} alt='Vendorlist' />
                <div className='AddManual_subheading'>
                    <p>1. Enter manually</p>
                </div>
            </div>
            <div className='AddManual_content'>
                <div className='AddManual_heading'>
                    <h2>Add Manually</h2>
                </div>
            </div>
            <div className='AddManual_button'>
                <Button type='primary' onClick={handleButtonClick}>Add new entry</Button>
            </div>
        </section>
    )
}
