import React from "react";
import { Avatar } from "antd";
import './NotificationListComponent.css'
import { useSelector } from "react-redux";

export default function NotificationListComponent({ notifications }) {
  const userData = useSelector((state) => state.user);
  const filteredNotifications = notifications.filter(item => item.type === userData?.orgType); 
  
  if (filteredNotifications.length === 0) {
    return <div className="NotificationListComponent_subContainer" style={{alignItems:'center'}}>
        <p>You have no notifications</p>
    </div>
  }

  return (
    <>
      {filteredNotifications.map((notification, index) => (
        <div className="NotificationListComponent_container" key={index}>
          <Avatar src={notification.avatarUrl} />
          <div className="NotificationListComponent_subContainer">
            <div className="NotificationListComponent_title">
              <h4>{notification.sender}</h4>
              <p>{notification.timestamp}</p>
            </div>
            <h5>{notification.message}</h5>
          </div>
        </div>
      ))}
    </>
  );
}
