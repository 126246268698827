import React from 'react';

export default function FeatureComponent({ data }) {
    return (
        <section className='VendorRank_firstContainer'>
            <img src={data.imgSrc} alt='' />
            <div className='VendorRank_infoContainer'>
                <h1>{data.heading}</h1>
                <div className='VendorRank_infoDescription'>
                <h4>{data.subHeading}</h4>
                    <ul>
                        {data.list.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <h4>{data.footer}</h4>
            </div>
        </section>
    );
}
