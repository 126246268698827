import { fetchData } from '../services/HomeService';
import _ from 'lodash';
import { showErrorMessage, showSuccessMessage } from '../utils';
import { uploadFile } from './UploadService';

export const getAttachmentTemplate = (label, key) => {
  return {
    fieldType: "Attachment",
    fieldSection: "Others",
    fieldKey: key,
    fieldValue: "",
    fieldLabel: label,
    fieldValidator: "",
    fieldStyle: {},
  };
};

export const AttachmentService = (partnerId) => {

  const fetchAttachmentsTemplate = async () => {
    const resp = await fetchData(`/partners/register/attachment`);
    if (resp.success) {
      return resp.data;
    }
    return null;
  };

  const fetchAttachments = async () => {
    const template = await fetchAttachmentsTemplate();
    if (template) {
      const data = await fetchData(`/activities/action/attachment?pid=${partnerId}&fields[]=meta.durl_link&fields[]=meta.vurl_link`);
      if (_.isArray(data) && data.length > 0) {
        const _tempData = _.cloneDeep(template);
        for (const datum of data) {
          if (!_tempData[datum.actionKey]) _tempData[datum.actionKey] = getAttachmentTemplate('Attached', datum.actionKey);
          _tempData[datum.actionKey]['fieldValue'] = datum.actionValue;
          if (datum.meta.label && datum.meta.label.trim() !== '') {
            _tempData[datum.actionKey]['fieldLabel'] = datum.meta.label;
          }
          _tempData[datum.actionKey]['fieldMeta'] = datum.meta;
        }
        return _tempData;
      }
      return template
    }
    return {};
  };
  
  const handleNewAttachment = (template) => {
    const _allSections = _.groupBy(_.values(template), 'fieldSection');
    if (!_allSections['Others']) _allSections['Others'] = [];
    const _allOptions = _.map(_allSections, (values, key) => {
      return {
        section: key,
        label: `${key} ${values.length + 1}`,
        value: `${key.toLowerCase()}_${values.length + 1}_${new Date().getTime()}`,
      };
    });
    return _allOptions;
  };

  const uploadFileToDrive = async (file, fieldLabel, fieldKey = 'temp_key_1', expiry = null) => {
    try {
      const formdata = new FormData();
      formdata.append('pid', partnerId);
      formdata.append('file', file);
      formdata.append('key', fieldKey);
      formdata.append('expiry', expiry);
      const response = await uploadFile('/uploads/create/org', formdata);
      if (response.success) {
        showSuccessMessage('Successfully submitted!!');
        return {
          success: true,
          data: {
            fieldValue: response.data.file_name,
            fieldMeta: {
              id: response.data.id,
              durl_link: response.data.download_url,
              expiry_date: response.data.expiry_date || expiry,
            },
          },
        };
      } else {
        showErrorMessage(response.status === 401 ? "Please grant drive access!!": 'Something went wrong!!');
        return { success: false };
      }
    } catch (error) {
      showErrorMessage('Something went wrong!!');
      return { success: false };
    }
  };

  return {
    fetchAttachmentsTemplate,
    fetchAttachments,
    handleNewAttachment,
    uploadFileToDrive,
  };
};
