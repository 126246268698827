import { Avatar, Button, Dropdown, Menu, Tabs } from 'antd';
import React, { useState } from 'react'
import StatusPopoverComponent from '../../common/StatusPopoverComponent';
import { BASE_IMG } from '../../../constants';
import { InfoCircleOutlined, MoreOutlined, PictureOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';

export default function QuoteAttachment({type2, files, popoverGrant, validToken, openPopupForAuthorization, setPopoverGrant, handleDelete, handleFiles, fileCounts}) {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  let fileLength, fileMap;

  if (type2 === 'ModalApprovals') {
    switch (activeTab) {
      case '1':
        fileLength = files.all?.length || 0;
        fileMap = files.all || [];
        break;
      case '2':
        fileLength = files.sent?.length || 0;
        fileMap = files.sent || [];
        break;
      case '3':
        fileLength = files.received?.length || 0;
        fileMap = files.received || [];
        break;
      default:
        fileLength = 0;
        fileMap = [];
        break;
    }
  } else {
    fileLength = files.length || 0;
    fileMap = files || [];
  }

  return (
    <section className="Quotation_section">
    <div className="Quotation_subtitle">
      <span>Attachments</span>
      {!type2 && (
        !validToken? (
              <Button className='Attachment_access' onClick={openPopupForAuthorization}><img src={`${BASE_IMG}/dashboard-img/dashboard_icons/white_drive.svg`} alt=''/> Grant Access</Button>
          ) : (
              <div className='ProfileView_grant'>
                  <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/blue_drive.svg`} alt=''/>
                  <span>Access Granted</span>
                  <StatusPopoverComponent
                      className = {'ProfileView_tickPopover'}
                      image={`${BASE_IMG}/check_circle.svg`}
                      popoverContent={
                          <div className='ListComponent_tick'>
                          <InfoCircleOutlined />
                          <div className='ListComponent_tickDetail' >You have successfully granted access to your google drive</div>
                          </div>
                      }
                      popoverVisible={popoverGrant}
                      setPopoverVisible={() => setPopoverGrant(!popoverGrant)}
                      />
              </div>
          )
      )}
    </div>
    {/* TODO : enable for two way  */}
    {type2 ==='ModalApprovals' &&
      <div className='Home_tabInfo'>
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab={`All (${fileCounts.all})`} key="1" />
            <TabPane tab={`Sent (${fileCounts.sent})`} key="2" />
            <TabPane tab={`Received (${fileCounts.received})`} key="3" />
        </Tabs>
      </div>
    }
    <div className="Quotation_attachment">
      <div className="Quotation_eachAttachmentRow">
      {fileLength > 0 ? (
        fileMap?.map((file, index) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <a href={file.meta?.murl_link} target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </Menu.Item>
              <Menu.Item onClick={() => handleDelete(file)}>
                Delete
              </Menu.Item>
            </Menu>
          );
         return ( <div className="Quotation_eachAttachment" key={index}>
            <a href={file.meta?.vurl_link} target="_blank" rel="noopener noreferrer">
            <div className="Quotation_eAr" >
              <Avatar>
                <PictureOutlined />
              </Avatar>
              <div className="Quotation_eAn">
                <span style={{ whiteSpace: 'nowrap' }}>
                  {file?.actionValue.length > 15 ? file?.actionValue.slice(0, 15) + '...' : file?.actionValue }
                  <span style={{ color: "#D9D9D9" }}>•</span>{" "}
                  <span></span>
                </span>
                <span>By {file.author || 'RazeID'}</span>
              </div>
            </div>
            </a>
            <Dropdown overlay={menu}>
              <MoreOutlined className="ListComponent_moreIcon" />
            </Dropdown>
          </div>
        )})
        ): 
        <span className="Quotation_nofile">No attachments</span>
        }
      </div>
    </div>
  </section>
  )
}
