import React from 'react'
import { Link } from 'react-router-dom';
import { HOMEPAGE_REDIRECT_ROUTE } from '../../constants';
import { useSelector } from 'react-redux';

export default function InsightNudgeComponent({insightsData}) {
    const userData = useSelector((state) => state.user); 
    const top2InsightsData = insightsData.slice(0, 2);

  return (
    <div className="InsightsNudgeComponent_container">
        <div className="InsightsNudgeComponent_title">
            <h5 style={userData?.orgType ==='vendor' ? {color: '#008065'}: {}}>Get your insights</h5>
            <Link target='_blank' to={`${HOMEPAGE_REDIRECT_ROUTE}/blogs`} style={userData?.orgType ==='vendor' ? {color: '#008065'}: {}}>View all</Link>
        </div>
        <div className="InsightsNudgeComponent_cardConatiner">
            {top2InsightsData.map((item, index) => (
            <Link className="InsightsNudgeComponent_card" key={index} to={`${HOMEPAGE_REDIRECT_ROUTE}/blog/${item.link}`} target='_blank'>
                <img src={item.image} alt="" />
                <div className="InsightsNudgeComponent_text">
                    <h5>{item.title}</h5>
                    <p>By {item.author} &nbsp; | &nbsp; {item.duration}</p>
                </div>
            </Link>
            ))}
        </div>
    </div>
  )
}