import React, { useState } from 'react'
import { Select, Radio, Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from '@ant-design/icons';
import { getLabelFromValue } from '../../utils';

const { Option } = Select;

export default function SortBySelectPanel({ properties:options, handleSortSelection}) {

  const [selectedOption, setSelectedOption] = useState(null);
  const [arrowUpDirection, setArrowUpDirection] = useState(true);

  const handleOptionChange = (optionValue) => {
    setSelectedOption(optionValue);
    handleSortSelection(optionValue, arrowUpDirection ? 'asc' : 'desc')
  };

  const toggleArrowDirection = () => {
    if (selectedOption) {
      setArrowUpDirection(!arrowUpDirection);
      handleSortSelection(selectedOption, !arrowUpDirection ? 'asc' : 'desc');
    }
  };
      
  return (
    <Space>
      <Select  placeholder='Sort by' value={getLabelFromValue(selectedOption, options)}>
        {options.map((option, index) => (
          <Option key={index} value={option.value}>
            <Radio onChange={() => handleOptionChange(option.value)} checked={selectedOption === option.value}>
              {option.label}
            </Radio>
          </Option>
        ))}
      </Select>
      {selectedOption ? (arrowUpDirection ? <ArrowUpOutlined onClick={toggleArrowDirection} /> : <ArrowDownOutlined onClick={toggleArrowDirection} />) : <SwapOutlined className='filter_icon' />}
    </Space>
  )
}
