import _ from 'lodash'
import { BASE_URL } from "../constants";

export const insertApiCall = async ({requestData, registerType, dataValidationSwitchChecked}) => {
    try {
        if (_.isEmpty(requestData) || !(registerType)) {
            return { success: false, id: "" };
        }
        const validateFormQuery = registerType === 'vendor' ? `validateForm=${dataValidationSwitchChecked ? 'yes' : 'no'}` : '';
        const formNameQuery = requestData.formname ? `formname=${requestData.formname}` : '';
        const queryString = [validateFormQuery, formNameQuery].filter(Boolean).join('&');

        const apiUrl = `${BASE_URL}/partners/register/${registerType}?${queryString}`;
        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        };
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (response.ok){
            if (data.success) {
                return { success: true, id: data.id, message: data.message };
            } else {
                return { success: false, id: "", message: data.message };
            }
        }else{
            return { success: false, id: "", message: data.message || (_.map(data, (da) => `${da.msg}-${da.param}`).join(", ")) };
        }
    } catch (error) {
        console.error(error.message);
        return { success: false };
    }
};

 export const updateApiCall = async ({requestData, source_entity, pid, registerType}) => {
    try {
        if (_.isEmpty(requestData) || !(pid && source_entity && registerType)) {
            return { success: false, id: "" };
        }
        const apiUrl = `${BASE_URL}/partners/${pid}/${registerType}/fields?src_entity=${source_entity}&validateForm=yes`;
        const requestOptions = {
            method: 'PUT', 
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fields: requestData }),
        };

        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();

        if (data.success) {
            return { success: true, id: data.id, message: data.message };
        } else {
            return { success: false, id: "", message: data.message };
        }
    } catch (error) {
        console.error(error.message);
        return { success: false };
    }
};

export const formTemplate = async (data, src_entity, name, src_id = '') => {
    if (src_entity !== 'users' & !src_id) return {}
    if (_.isEmpty(data) || !name || !src_entity) return {}

    return {
        name,
        src_id,
        src_entity,
        ...data
    };
};
