import React from "react";
import { Button, Input, Tooltip } from "antd";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";

export default function EditableLabel({
  data,
  isEditing,
  handleFieldLabelChange,
  handleSaveType,
  handleEditClick,
  formPrefillData,
  className,
  title,
  value = null,
  type
}) {
  return (
    <>
      {isEditing === data.fieldKey ? (
        <>
          <Input type="text" value={data.fieldLabel} onChange={(e) => handleFieldLabelChange(e, data.fieldKey)}/>
          {(type && value?.fieldValue) || (!type && data.fieldValue) ? (
            <Button onClick={() => handleSaveType(data)}>
              <CheckOutlined />
            </Button>
          ) : (
            <Tooltip title={title}>
              <Button disabled>
                <CheckOutlined />
              </Button>
            </Tooltip>
          )}
        </>
      ) : (
        <>
            <p className={className} >{data.fieldLabel}</p>
            {data?.fieldStyle?.showEditOutlinedIcon && formPrefillData && (
                <>: <EditOutlined onClick={() => handleEditClick(data.fieldKey)} /></>
            )}
        </>
      )}
    </>
  );
}
