import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { List, Avatar, Popover, Button, Checkbox } from 'antd';
import _ from 'lodash';

import './style.css';
import ModalDialogComponent from '../../common/ModalDialogComponent';
import { fetchData, postData, putData } from '../../../services/HomeService';
import { createNote, updateNote, deleteNote } from '../../../services/ActivitiesService';
import ModalNotesComponent from './ModalNotesComponent';
import ModalEditDetailsComponent from './ModalEditDetailsComponent';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_COMPLETED_STATUS, LINK_URL_NOTES, LINK_TYPE_MODAL, LINK_URL_VENDOR_PROFILE,  LINK_ATTACHMENTS,
    LINK_TYPE_ROUTE_INTERNAL, LINK_URL_PAYWALL, LINK_URL_QUOTES, LINK_EDIT_DETAILS, LINK_EMAIL_VERIFICATION, BASE_IMG, LINK_API_CALL } from '../../../constants';

import ModalPayWallComponent from './ModalPayWallComponent';
import ModalQuotesComponent from './ModalQuotesComponent';
import ModalCategoryComponent from './ModalCategoryComponent';
import { formatToCustomTime, showErrorMessage, showLoader, showSuccessMessage } from '../../../utils';
import ModalAttachmentComponent from './ModalAttachmentComponent';
import { deleteData } from '../../../services/HomeService';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import { handleVendorAction } from '../../../services/RemoveVendor';
import ListInfoComponent from '../../common/ListInfoComponent';

export default function ListViewComponent({ viewType, vendorData, AvatarColor, categoriesList, setVendorData, setPagination, pagination, selectAll, setSelectAll, vendorSelections, setVendorSelections, setAllSelectedVendorId }) {
    const [showPopup, setShowPopup] = useState(null);
    const [popoverOnboarding, setPopoverOnboarding] = useState(null);
    const [partnerId, setpartnerId] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const [vendorInfo , setVendorInfo] = useState(null);
    const [relationId, setRelationId] = useState(null);
    const [vendorPartnerId, setVendorPartnerId] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState(null);

    const [notesData, setNotesData] = useState([]);
    const [activeNoteData, setActiveNoteData] = useState(null);
    const [isDailogBoxVisible, setIsDailogBoxVisible] = useState(false);
    const [isNotesModalVisible, setIsNotesModalVisible] = useState(null);
    const [isPayWallModalVisible, setIsPayWallModalVisible] = useState(false);
    const [isQuotesModalVisible, setIsQuotesModalVisible] = useState(false);
    const [isEditorModalVisible, setIsEditModalVisible] = useState(false);
    const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
    const [isAttachmentsModalVisible, setIsAttachmentsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 

    const navigate = useNavigate();

    const handleTrippleDot = (item, visible) => {
        if (visible) {
            setShowPopup(item);
        } else {
            setShowPopup(null);
        }
    };

    const renderModalComponent = (data, linkURL, key) => {
        switch (linkURL) {
            case LINK_URL_NOTES:
                openNotesModal(data)
                break;
            case LINK_URL_PAYWALL: 
                openPayWallModal(key);
                break;  
            case LINK_URL_QUOTES: 
                openQuotesModal(data)
                break;  
            case LINK_EDIT_DETAILS: 
                openEditModal(data);  
                break;
            case LINK_ATTACHMENTS: 
                openAttachmentModal(data);  
                break;
            default:
                break;
        }
    }

    const handleOptionClick = (item, option) => {
        setShowPopup(null);
        if (option.linkType === LINK_TYPE_MODAL){
            renderModalComponent(item, option.linkURL, option.key)
        } else if (option.linkType === LINK_TYPE_ROUTE_INTERNAL){
            // TODO: Make the query param depended on response- change API
            if (option.linkURL && option.linkURL.includes(LINK_URL_VENDOR_PROFILE)) {
                navigate(`${option.linkURL}?id=${item.sinkPartnerId}`);
            } else if (option.linkURL ===  LINK_EMAIL_VERIFICATION) {
                sendEmailVerification(item.sinkPartnerId); 
            } 
        } else if(option.linkType === LINK_API_CALL){
            openRemoveVendorModal(item._id)
        }
    }

    const openRemoveVendorModal = (id) => {
        setSelectedVendorId(id)
        setShowRemoveModal(true);
    }
    const closeRemoveVendorModal = () => {
        setShowRemoveModal(false);
    }

    const openEditModal = (data) => {
        setIsEditModalVisible(true);
        setVendorPartnerId(data.sinkPartnerId);
    }

    const closeEditModal = () => {
        setIsEditModalVisible(false);
    }

    const openAttachmentModal = (data) => {
        setVendorPartnerId(data.sinkPartnerId);
        setIsAttachmentsModalVisible(true);
    };

    const closeAttachmentModal = () => {
        setIsAttachmentsModalVisible(false);
    }

    const sendEmailVerification = async (id) => {
        const requestData = { pid: id }
        setIsLoading(true); 
        try {
            const response = await postData("/notifications/onboard-partner", requestData);
            if (response.success) {
              showSuccessMessage("Request successfully submitted!!");
            } else {
              showErrorMessage(response.message);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectedVendorAction = async (id, action) => {
        try {
            const { success } = await handleVendorAction(id, action);
            if (success) {
                const updatedVendorData = vendorData.filter(vendor => vendor._id !== id);
                const updatedPagination = {
                    ...pagination,
                    total: pagination.total - 1
                };
                setVendorData(updatedVendorData);
                setPagination(updatedPagination);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setShowRemoveModal(false);
        }
    };

     const openQuotesModal = (data) => {
        setIsQuotesModalVisible(true);
        setVendorInfo(data);
     }

      const CloseQuotesModal = () => {
        setIsQuotesModalVisible(false);
      };

    const openPayWallModal = (key) => {
        setIsPayWallModalVisible(true);
        setSelectedKey(key);
    };

    const ClosePayWallModal = () => {
        setIsPayWallModalVisible(false);
    };

    const fetchNotes = async (id= partnerId) => {
        const data = await fetchData(`/activities/action/note?pid=${id}`);
        setNotesData(data);
    };

    const openNotesModal = async (item) => {
        setIsNotesModalVisible(true);
        setpartnerId(item.sinkPartnerId);
        fetchNotes(item.sinkPartnerId);
    };

    const handlePopupClose = () => {
        setIsDailogBoxVisible(false);
        setActiveNoteData(null);
    };

    const setNoteAction = (id, value, actionType) => {
         if (actionType === 'edit') {
            if (!value) window.alert('You cannot set empty fields')
        } else if (actionType === 'delete') {
            if (!id) window.alert('You cannot delete this fields')
        } else if (actionType === 'add') {
            if (id) window.alert('You cannot add to this fields')
        }
        //TODO: Remove note identifier from key val pairs
        setActiveNoteData({
            noteId: id,
            noteValue: value,
            noteAction: actionType,
        });
    };

    const handleModal = async (data={}) => {
        try {
            if (data && data.noteAction === 'delete' && data.noteId) {
                await deleteNote( data.noteId )
            } else {
                if (data && data.noteAction === 'edit' && data.noteId) {
                    await updateNote({ id: data.noteId, actionValue: data.noteValue });
                } else if (data && data.noteAction === 'add' && data.noteValue !== '') {
                    const requestBody = {
                        type: 'note',
                        pid: partnerId,
                        value: data.noteValue,
                    };
                    await createNote(requestBody);
                }
            }
            handlePopupClose();
            fetchNotes();
        } catch (error) {
            console.error('API error:', error);
        }
    };

    const handleCategoryAssignment = async (category) => {
        try {
            if (!relationId || !category) throw new Error("Some mandatory fields are empty");
            const response = await putData(`/relations/${relationId}`, { category });
            if (response.success) {
                setVendorData((prevVendorData) => 
                    prevVendorData.map((_vendor) =>
                        _vendor._id === relationId ? { ..._vendor, category: _.find(categoriesList, { label: category }).label }
                            : _vendor
                    )
                );
                showSuccessMessage(response.message || 'Request successfully submitted!!');
            } else {
                showErrorMessage(response.message);
            }
        } catch (error) {
            console.log(error.message)
            showErrorMessage('Some error happened!!');
        }
    };

    const handleAssignCategoryButtonClick = (rid) => {
        setRelationId(rid)
        setIsCategoryModalVisible(true);
    };

    const CloseCategoryModal = () => {
        setIsCategoryModalVisible(false);
    };

    const handleSelectToggle = (_id) => {
        const updatedVendorSelections = {
            ...vendorSelections,
            [_id]: !vendorSelections[_id],
        };
        const allSelected = vendorData.every((vendor) => updatedVendorSelections[vendor._id]);
        const selectedVendorIds = Object.keys(updatedVendorSelections).filter(_id => updatedVendorSelections[_id]);
        
        if (!updatedVendorSelections[_id]) {
            delete updatedVendorSelections[_id];
        }
        setVendorSelections(updatedVendorSelections);
        setAllSelectedVendorId(selectedVendorIds);
        setSelectAll(allSelected); 
    };

    return (
        <section className="ListComponent_container" style={{ width: '100%' }}>
            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                dataSource={vendorData}
                renderItem={(item) => (
                    <List.Item>
                    <div className="ListComponent_box2">

                    <ListInfoComponent
                        name={item.sinkPartnerName}
                        id={item._id}
                        AvatarColor={AvatarColor}
                        type={'VendorList'}
                        badges={item.options.badges}
                        vendorSelections={vendorSelections}
                        selectAll={selectAll}
                        pagination={pagination}
                        handleSelectToggle={handleSelectToggle}
                        popoverOnboarding={popoverOnboarding}
                        setPopoverOnboarding={setPopoverOnboarding}
                    />
                   <div className={pagination.total > 1 ? 'ListComponent_subCategory' : 'ListComponent_subCategory2'}>
                        {item.category ? (
                            <p>{_.find(categoriesList, { label: item.category })?.label}</p>
                        ) : (
                            <p onClick={() => handleAssignCategoryButtonClick(item._id)} style={{ color: '#4800FF', cursor: 'pointer' }}>Assign</p>
                        )}
                    </div>
                    <div className="ListComponent_box1" style={{width: '100%', justifyContent:'flex-end'}}>
                            {item.updatedAt ? (
                                <p>Last Updated on {formatToCustomTime(item.updatedAt)}</p>
                            ) : item.createdAt ? (
                                <p>Created on {formatToCustomTime(item.createdAt)}</p>
                            ) : ''}
                            {item.options && item.options.horizontalbar && (
                                <>
                                    {item.options.horizontalbar?.map((option) => (
                                        <span key={option.key} style={{ cursor: 'pointer', color: '#4800FF' }} onClick={() => handleOptionClick(item, option)}>
                                            {option.label}
                                        </span>
                                    ))}
                                </>
                            )}
                           { ONBOARDING_COMPLETED_STATUS !== `${item.state}-${item.status}` 
                            && <p>
                                    {item.state} {item.status}
                                </p>}
                            <Popover
                                content={
                                    <div className="list_popup">
                                       {item.options && item.options.tripledots?.map((option) => (
                                            <span key={option.key} onClick={() => handleOptionClick(item, option)}>
                                                {option.label}
                                            </span>
                                        ))}
                                    </div>
                                }
                                overlayClassName="list_popover"
                                trigger="click"
                                placement="bottomLeft"
                                open={showPopup === item}
                                onOpenChange={(visible) => handleTrippleDot(item, visible)}>
                                    <MoreOutlined className="ListComponent_moreIcon"/>
                            </Popover>
                      </div>
                    </div>
                </List.Item>
                )}
            />
            {isLoading && showLoader('sending')} 
            <ModalNotesComponent
                modalData={notesData}
                setIsModalVisible={setIsNotesModalVisible}
                setDailogBoxVisible={setIsDailogBoxVisible}
                isModalVisible={isNotesModalVisible}
                handleModalAction={setNoteAction}
            />

            <ModalDialogComponent
                type={'note'}
                modalData={activeNoteData}
                isVisible={isDailogBoxVisible}
                handleModalAction={handleModal}
                onCancel={handlePopupClose}
            />

            <ModalPayWallComponent
                isVisible={isPayWallModalVisible}
                onClose={ClosePayWallModal}   
                selectedKey={selectedKey} 
            />   
            <ModalQuotesComponent
                isVisible={isQuotesModalVisible}
                onClose={CloseQuotesModal}
                modalData={vendorInfo}
            />
            {isEditorModalVisible && (
            <ModalEditDetailsComponent
                registerType={viewType}
                isVisible={isEditorModalVisible}
                onClose={closeEditModal}
                modalData={vendorPartnerId}
            />
            )}
            <ModalCategoryComponent 
                modalData={categoriesList}
                isVisible={isCategoryModalVisible} 
                onClose={CloseCategoryModal}
                handleModalAction={handleCategoryAssignment}
            />
            {isAttachmentsModalVisible && (
            <ModalAttachmentComponent
                isVisible={isAttachmentsModalVisible}
                onClose={closeAttachmentModal}
                modalData={vendorPartnerId}
            />
            )}
            <RequestNudgeComponent
                type={'removeVendor'}
                action={'remove'}
                data={selectedVendorId}
                isVisible={showRemoveModal}
                onClose={closeRemoveVendorModal}
                handleNudgeSubmit={handleSelectedVendorAction}
            />
        </section>
    );
}