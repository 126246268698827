import _ from 'lodash';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import AttachFilesComponent from '../vendor-profile/AttachFilesComponent';
import { showErrorMessage, showSuccessMessage } from '../../../utils';
import ModalUploadComponent from '../vendor-profile/ModalUploadComponent';
import { AttachmentService, getAttachmentTemplate } from '../../../services/AttachmentService';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import { deleteData } from '../../../services/HomeService';

export default function ModalAttachmentComponent({ isVisible, onClose, modalData: partnerId }) {
  
  const {
    fetchAttachments,
    handleNewAttachment,
    uploadFileToDrive,
  } = AttachmentService(partnerId);

  const [attachmentsData, setAttachmentsData] = useState({});
  const [uploadOptions, setUploadOptions] = useState([]);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [activeAttachmentData, setActiveAttachmentData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [attachmentID, setAttachmentID]=useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (partnerId) {
        const _data = await fetchAttachments();
        setAttachmentsData(_data);
      }
    };
  
    fetchData();
  }, [partnerId]);

  const openUploadModal = (section, label, value) => {
    setActiveAttachmentData({ section, label, value });
    setUploadOptions([]);
    setIsUploadModal(true);
  };

  const handleNewAttachmentClick = () => {
    const options = handleNewAttachment(attachmentsData);
    setUploadOptions(options);
    setIsUploadModal(true);
  };

  const closeUploadModal = () => {
    setActiveAttachmentData({});
    setIsUploadModal(false);
  };

  const handleModalSubmit = async (file, fieldLabel, fieldKey, expiry) => {
    setIsLoading(true);
    const response = await uploadFileToDrive(file, fieldLabel, fieldKey, expiry);
    if (response.success) {
      showSuccessMessage('Successfully submitted!!');
      setIsUploadModal(false);
      const _tempData = _.cloneDeep(attachmentsData)
      if (!_tempData[fieldKey]) _tempData[fieldKey] = getAttachmentTemplate(fieldLabel, fieldKey)
      _tempData[fieldKey]['fieldValue'] = response.data?.fieldValue
      _tempData[fieldKey]['fieldMeta'] = response.data?.fieldMeta
      setAttachmentsData(_tempData);
    } else {
      showErrorMessage('Something went wrong!!');
    }
    setIsLoading(false);
  };


    // * ----------------------- Attachment Delete Management Functions--------------------------------

    const openDeleteModal = (_id, key) => {
      setAttachmentID({_id, key});
      setDeleteModal(true);
  }
  const closeDeleteModal = () => {
      setDeleteModal(false);
  }

  const handleDeleteAttachment = async(data) => {
      if(_.isEmpty(data)) return;
      try {
          const response = await deleteData(`/activities/${data._id}`, {});
          if (response.success) {
            showSuccessMessage("Deleted successfully");
            const updatedAttachmentsData = { ...attachmentsData };
            if (updatedAttachmentsData[data.key]) {
                updatedAttachmentsData[data.key].fieldValue = "";
                updatedAttachmentsData[data.key].fieldLabel = response?.data?.meta?.label;
                setAttachmentsData(updatedAttachmentsData);
            }
          } else {
            showErrorMessage(response.message);
          }
        } catch (error) {
          showErrorMessage(error.message);
        } finally {
          setDeleteModal(false); 
        }
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      className='EditModal_container'>
      <div className='EditModal_subContainer'>
        <AttachFilesComponent attachedData={attachmentsData} openUploadModal={openUploadModal} onAttachNew={handleNewAttachmentClick} openDeleteModal={openDeleteModal} />
      </div>
      <ModalUploadComponent
        isVisible={isUploadModal}
        onClose={closeUploadModal}
        modalData={uploadOptions}
        setUploadData={activeAttachmentData}
        handleModalSubmit={handleModalSubmit}
        isLoading={isLoading}
      />

      <RequestNudgeComponent
          type={'deleteAttachment'}
          data={attachmentID}
          isVisible={deleteModal}
          onClose={closeDeleteModal}
          handleNudgeSubmit={handleDeleteAttachment}
      />
    </Modal>
  );
}
