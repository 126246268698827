import { CheckOutlined, CopyOutlined, DeleteFilled, DownloadOutlined, EditOutlined, InfoCircleOutlined, LinkOutlined, PlusSquareOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Popover, Select, Switch, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { showErrorMessage, showLoader, showSuccessMessage } from '../../../utils';
import { postData } from '../../../services/HomeService';
import { BASE_URL } from '../../../constants';
import { useSelector } from 'react-redux';
import { fetchQuoteWithQuoteId } from '../vendor-quotations/quotationService';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const { Option } = Select;

const unitsList = [
  { label: 'units', value: 'units' },
  { label: 'kg', value: 'kg' },
  { label: 'gm', value: 'g' },
  { label: 'ltr', value: 'l' },
  { label: 'ml', value: 'ml' },
  { label: 'mtr', value: 'm' },
  { label: 'ft', value: 'ft' },
  { label: 'inch', value: 'inch' }
];

export default function ModalQuotesComponent({ isVisible, onClose, modalData, type, quoteID, setTrigger}) {
  const [form] = Form.useForm();
  const [itemsCounter, setItemsCounter] = useState([{ quantity: { unit: 'units' } }]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState('Quotation Title');
  const [popoverLink, setPopoverLink] = useState(false);
  const [seeForm, setSeeForm] = useState(null)
  const [loading, setLoading] = useState({ isLoading: false, isFetchingQuote: false });
  const [disableFields, setDisableFields] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [quoteStore, setQuoteStore] = useState(null);
  const [quoteState, setQuoteState] = useState({quoteIdSend: null, isEditForm: false });
  const [quoteIdChange, setQuoteIdChange] = useState(false);
  const [id, setId] = useState();
  const [linkquote, setLinkQuote] = useState(false);

  const quoteId = Form.useWatch('quoteId', form);
  const [options, setOptions] = useState([{ label: 'New', value: '' }]);
  const user = useSelector((state) => state.user);
  const generateNewQuoteId = () => 'QID-' + Math.floor(Math.random() * 100000);
  useEffect(() => {
    const DEFAULT_QUOTE_ID = generateNewQuoteId()

    fetch(`${BASE_URL}/quotations/quoteids/${user.org}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const newOptions = data.map(item => ({ label: item.quoteId, value: item.quoteId }));
        setOptions([{ label: 'New', value: DEFAULT_QUOTE_ID }, ...newOptions]);
      })
      .catch(error => console.error('Error:', error));
  }, [isVisible, form]);

  const onFinish = async (values) => {
    setLoading({ ...loading, isLoading: true });
    const requestData = quoteState.isEditForm
  ? { pid: _.get(modalData, 'sinkPartnerId', ''), quote: { ...values, quoteId: quoteStore.quoteId ,status: 'pending' } }
  : { pid: _.get(modalData, 'sinkPartnerId', ''), quote: { ...values, status: 'pending' } };

    if (type === 'seeFormDraft') {
      requestData.quote = { ...values, status: 'pending' };
    }
    try {
      const response = await postData("/quotations", requestData);
      if (response.status == 'success') {
        showSuccessMessage("Successfully submitted!!", 3);
        if(type === 'seeFormDraft' || type === 'newQuote'){
          setTrigger(true)
        }
      } else {
        showErrorMessage("Something went wrong!!", 3);
      }
    } catch (error) {
      showErrorMessage("Something went wrong!!", 3);
    } finally {
      setLoading({ ...loading, isLoading: false });
      onClose();
    }
  };

  const handleSaveDraft = async () => {
    const values = form.getFieldsValue(true);
    setLoading({ ...loading, isLoading: true });
        const requestData = quoteState.isEditForm
  ? { pid: _.get(modalData, 'sinkPartnerId', ''), quote: { ...values, quoteId: quoteStore.quoteId ,status: 'draft' } }
  : { pid: _.get(modalData, 'sinkPartnerId', ''), quote: { ...values, status: 'draft' } };

  if (type === 'seeFormDraft') {
    requestData.quote = { ...values, status: 'draft' };
  }
    try {
      const response = await postData("/quotations", requestData);
      if (response.status == 'success') {
        showSuccessMessage("Saved as draft successfully!!", 3);
        if(type === 'seeFormDraft' || type === 'newQuote'){
          setTrigger(true)
        }
      } else {
        showErrorMessage("Something went wrong!!", 3);
      }
    } catch (error) {
      showErrorMessage("Something went wrong!!", 3);
    } finally {
      setLoading({ ...loading, isLoading: false });
      onClose();
    }
  };

  const handleAddNewItem = () => {
  const newItem = { quantity: { unit: 'units' } };
  const updatedItemsCounter = [...itemsCounter, newItem];
  setItemsCounter(updatedItemsCounter);
  const allValues = form.getFieldsValue();
  const currentItems = allValues.items || [];
  const updatedFormItems = [...currentItems, newItem];

  form.setFieldsValue({
    ...allValues,
    items: updatedFormItems
  });
};

  const handleDeleteItem = (indexToDelete) => {
    const newItems = [...itemsCounter];
    newItems.splice(indexToDelete, 1);
    const allValues = form.getFieldsValue();
    const updatedFormValues = {
      ...allValues,
      items: allValues.items.filter((_, index) => index !== indexToDelete)
    };
  
    form.setFieldsValue(updatedFormValues);
    setItemsCounter(newItems);
  };

  const handleCopyQuoteId = (quoteId) => {
    if (quoteId) {
      navigator.clipboard.writeText(quoteId);
      message.success('Copied to clipboard');
    } else {
      message.warning('Please select a quote');
    }
  };

  const handleTitleEdit = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    handleFieldChange();
  };

  const handleTitleSave = () => {
    setIsEditingTitle(false);
  };
 const handleWarning = () => {
    showErrorMessage('Please Select existing quoteId');
 }
  const handleSwitchChange = (checked) => {
    if(checked) {
        handleSwitchChecked(checked);
    } else {
      setQuoteStore({ quoteId: generateNewQuoteId() });
    }
    setQuoteState({ ...quoteState, isEditForm: true });
    setQuoteIdChange(false)
    setSwitchChecked(checked);
    setDisableFields(checked);
  };

  const handleSwitchChecked = async (checked) => {
    if(checked) {
      handleSelectChange(id, 'switch');
      setQuoteStore({ quoteId: id });
    }
  };

  const handleLink = () => {
    setPopoverLink(!popoverLink);
  };

  const handleSelectChange = async (value, type) => {
    try {
      setId(value)
      if(type === 'select' || type ==='seeFormDraft') {
        setQuoteStore({ quoteId: generateNewQuoteId() });
        }
      if (value === options[0].value) {
        form.resetFields();
        setItemsCounter([{ quantity: { unit: 'units' } }]);
        setTitle('Quotation Title');
        setDisableFields(false);
        setSwitchChecked(false);
        setLinkQuote(false);
      } else {
        setLoading({ ...loading, isFetchingQuote: true });
        const response = await fetchQuoteWithQuoteId(value);
        const transformedResponse = {
          quoteId: response.quoteId,
          title: response.title,
          emails: response?.emailRecipient,
          description: response.description,
          items: response.items.map(item => ({
            item: item.name,
            quantity: {
              unit: item.unit,
              value: item.quantity
            },
            dueDate: dayjs(item.dueDate),
            minBudget: item.budget?.min,
            maxBudget: item.budget?.max,
            placeOfSupply: item.placeOfSupply,
            supplyDate: dayjs(item.supplyDate)
          })),
          templateURL: response?.templateURL
        };
        setItemsCounter(transformedResponse.items?.length ? transformedResponse.items : [{ quantity: { unit: 'units' } }]);
        setTitle(response.title || 'Quotation Title');
        setQuoteState({ ...quoteState, isEditForm: true });
        setLinkQuote(true);

        form.setFieldsValue(transformedResponse);
        setLoading({ ...loading, isFetchingQuote: false });
      }
      if (value == options[0].value) {
        form.setFieldsValue({ quoteId: value });
        setQuoteStore({ quoteId: value}); 
      }
    } catch (error) {
      message.error('Failed to fetch data from API');
    }
  };

  const handleFieldChange = () => {
    if (!quoteIdChange && quoteState.isEditForm && type !== 'seeFormDraft') {
      setQuoteStore({ quoteId: generateNewQuoteId()}); 
      setQuoteIdChange(true)
    }
  };
  

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      setItemsCounter([{ quantity: { unit: 'units' } }]);
      setIsEditingTitle(false);
      setTitle('Quotation Title');
      setDisableFields(false);
      setSwitchChecked(false);
      setQuoteState({isEditForm: false });
      setQuoteIdChange(false)
      setQuoteStore(null)
      setLinkQuote(false);
      setId(null)
    }
  }, [isVisible, form]);

  useEffect(() => {
    if(type) {
    if (type === 'seeForm') {
      setSeeForm(true)
    }
    if(type !== 'newQuote') {
      handleSelectChange(quoteID, type);
    }
  }
  }, [type, quoteID]);

  const initialValues = {
    title: title,
    quoteId: undefined,
    emails: [],
    description: '',
    templateURL: '',
    items: [{ quantity: { unit: 'units' } }]
  };

  const handleDownloadCSV = () => {
    const formData = form.getFieldsValue(true);

    const headers = [
      'Quote ID',
      'Title',
      'Emails',
      'Description',
      'Item',
      'Quantity',
      'Unit',
      'Date',
      'Min Budget',
      'Max Budget',
      'Place of Supply',
      'Supply Date'
    ];

    const rows = formData.items.map(item => [
      formData.quoteId,
      formData.title,
      formData.emails,
      formData.description,
      item.item,
      item.quantity.value,
      item.quantity.unit,
      dayjs(item.date).format('DD-MM-YYYY'),
      item.minBudget,
      item.maxBudget,
      item.placeOfSupply,
      dayjs(item.supplyDate).format('DD-MM-YYYY')
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const fileName = `quotation_${formData.quoteId}.csv`;
    saveAs(blob, fileName);
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      title="Ask Quote"
      footer={null}
      className='Quotes_modal'>
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <div className='Quotes_container'>
          <div style={{width: '100%'}}>
            <span style={{fontSize: '16px'}}>Quote</span>
            <div className='Quotes_firstContainer'>
            <div>
              <Form.Item name="quoteId" label="Auto-fill using a Quote Id:" rules={[{ required: true, message: 'Select a Quote Id' }]}>
                <Select placeholder="Select" onChange={(value) => handleSelectChange(value, 'select')} disabled={disableFields || seeForm}>
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="title" rules={[{ required: true, whitespace: true, message: 'Please provide a title' }]}>
                {isEditingTitle ? (
                  <div className='ProfileView_subTitleEditing'>
                    <Input onChange={handleTitleChange} value={title} maxLength={45} placeholder="Enter Quotation Title" autoFocus />
                    {title.trim() !== '' ? (
                      <CheckOutlined onClick={handleTitleSave} />
                    ) : (
                      <CheckOutlined style={{ cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }} />
                    )}
                  </div>
                ) : (
                  <span>
                    {title}
                    {!seeForm && <EditOutlined onClick={handleTitleEdit}/>}
                  </span>
                )}
              </Form.Item>
            </div>
            <div>
              <span className='quoteIDCopy'>Id: <b>{(quoteStore?.quoteId && type !== 'seeFormDraft') ? quoteStore?.quoteId : (quoteID || '######')}</b> <CopyOutlined onClick={() => handleCopyQuoteId((quoteStore?.quoteId && type !== 'seeFormDraft') ? quoteStore?.quoteId : quoteId)} /></span>
             {!seeForm && type !== 'seeFormDraft' && (
              <div className='Quotes_link'>
                  <span>Link
                    <Popover
                      content={
                        <div className='ListComponent_tick'>
                          <InfoCircleOutlined />
                          <div className='ListComponent_tickDetail'>This will be linked to the existing quotation request <Button>Know more</Button></div>
                        </div>
                      }
                      trigger="click"
                      placement="bottomRight"
                      open={popoverLink}
                      onOpenChange={handleLink}>
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                  <Switch size="small" onChange={linkquote? handleSwitchChange: handleWarning} checked={switchChecked}/>
                </div>
              )}
            </div>
            </div>
          </div>
          <div className='Quotes_secondContainer'>
            <div className='Quotes_secondSubContainer'>
              <span>Emails</span>
              <Form.Item name="emails" rules={[{ type: 'array', required: false, message: '' }]}>
                <Select mode="tags" placeholder="Type here..." autoSize={{ minRows: 1, maxRows: 6 }} disabled={seeForm} />
              </Form.Item>
            </div>
            <div className='Quotes_secondSubContainer'>
              <p>Description</p>
              <Form.Item name="description" rules={[{ required: true, whitespace: true, message: 'Please provide a description' }]}>
                <Input.TextArea placeholder="Type description here about the items in detail..." autoSize={{ minRows: 4, maxRows: 6 }} disabled={seeForm} />
              </Form.Item>
            </div>
            <div className='Quotes_items'>
              {itemsCounter.map((item, index) => (
                <div className='Quotes_itemsData' key={index}>
                  <div className='Quotes_itemTitle'>
                    <span>Add Item{index + 1}</span>
                    {index > 0 && (
                      <DeleteFilled onClick={() => handleDeleteItem(index)} className="deleteIcon" />
                    )}
                  </div>
                  <Typography className='Quotes_typography' onChange={handleFieldChange}>
                    <div>
                      <div>
                        <p>Name</p>
                        <Form.Item name={['items', index, 'item']} style={{ width: '14vw' }} rules={[{ required: true, whitespace: true, message: 'Provide name' }]}>
                          <Input placeholder='Type an item name...' disabled={(disableFields && !_.isEmpty(item.item)) || seeForm} />
                        </Form.Item>
                      </div>
                      <div>
                        <p>Quantity</p>
                        <Form.Item name={['items', index, 'quantity']} initialValue={{ value: '', unit: 'units' }} rules={[{ required: true, message: 'Provide quantity' }]}>
                          <Input.Group compact>
                            <Form.Item name={['items', index, 'quantity', 'value']} noStyle rules={[{ type: 'number', required: true, message: 'Provide value' }]}>
                              <InputNumber style={{ borderRadius: '3px 0 0 3px' }} placeholder='Quantity' disabled={(disableFields && item.quantity.value !== undefined && item.quantity.value !== '') || seeForm}/>
                            </Form.Item>
                            <Form.Item name={['items', index, 'quantity', 'unit']} noStyle rules={[{ required: true, message: 'Select unit' }]}>
                              <Select className='Quotes_units' disabled={(disableFields && item.quantity.value !== undefined && item.quantity.value !== '') || seeForm} onChange={handleFieldChange}>
                                {unitsList.map(unit => (
                                  <Option key={unit.label} value={unit.label}>
                                    {unit.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </div>
                      <div>
                        <p>Due Date</p>
                        <Form.Item name={['items', index, 'dueDate']} rules={[{ required: true, message: 'Select due date' }]}>
                          <DatePicker placeholder='Select a date' disabled={(disableFields && !_.isEmpty(item.dueDate)) || seeForm} />
                        </Form.Item>
                      </div>
                      <div>
                        <p>Budget</p>
                        <Form.Item name={['items', index, 'minBudget']} rules={[{ type: 'number', required: true, message: 'Provide minimum budget' }]}>
                          <InputNumber style={{ width: '100%' }} min={1} max={10000000} placeholder='Minimum' prefix={<span>&#x20B9;</span>} disabled={(disableFields && item.minBudget !== undefined && item.minBudget !== '') || seeForm}/>
                        </Form.Item>
                      </div>
                      <div className='Quotes_hr'>&ndash;</div>
                      <div>
                        <Form.Item name={['items', index, 'maxBudget']} rules={[{ type: 'number', required: true, message: 'Provide maximum budget' }]}>
                          <InputNumber style={{ width: '100%' }} min={1} max={10000000} placeholder='Maximum' prefix={<span>&#x20B9;</span>} disabled={(disableFields && item.maxBudget !== undefined && item.maxBudget !== '')|| seeForm}/>
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ alignItems: 'flex-start', width: '100%' }}>
                      <div style={{ width: '85%' }}>
                        <p>Places of supply</p>
                        <Form.Item name={['items', index, 'placeOfSupply']} rules={[{ type: 'array', required: true, message: 'At least one city name is required' }]}>
                          <Select mode="tags" placeholder="Type city names..." autoSize={{ minRows: 1, maxRows: 6 }} disabled={(disableFields && !_.isEmpty(item.placeOfSupply)) || seeForm} onChange={(value) => handleFieldChange({ placeOfSupply: value }, form.getFieldsValue())}  />
                        </Form.Item>
                      </div>
                      <div>
                        <p>Supply date</p>
                        <Form.Item name={['items', index, 'supplyDate']} rules={[{ required: true, message: 'Select supply date' }]}>
                          <DatePicker placeholder='Select a date' disabled={(disableFields && !_.isEmpty(item.supplyDate)) || seeForm} />
                        </Form.Item>
                      </div>
                    </div>
                  </Typography>
                </div>
              ))}
              {!seeForm && <div className='Quotes_addNew' onClick={handleAddNewItem}><PlusSquareOutlined /> Add new</div>}
            </div>
            <div className='Quotes_secondSubContainer'>
              <span> <LinkOutlined />Template URL</span>
              <Form.Item name="templateURL">
                <Input placeholder="Paste your own custom quote template doc link here" disabled={seeForm} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="Quotes_footer">
        {(type !== 'seeForm' ) && <Form.Item>
            <Button type="default" style={{ backgroundColor: 'transparent', border: '1px solid transparent', color: '#4800FF' }} onClick={handleSaveDraft}>
              Save as Draft
            </Button>
          </Form.Item>
        }
          <Form.Item>
            {seeForm ? 
              <Button type="primary" className='seeFormButton' onClick={handleDownloadCSV}><DownloadOutlined/> Download</Button>
              :
               !seeForm  && 
                <Button type="primary" htmlType="submit">{type === 'newQuote' ? 'Submit' : 'Send'}</Button>
            }
          </Form.Item>
        </div>
      </Form>
      {loading.isLoading && showLoader('Sending')}
      {loading.isFetchingQuote && showLoader('Fetching')}
    </Modal>
  );
}
