import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { Button, Checkbox, Input, Popover, Radio, Space, message, Modal, Row, Col } from 'antd';
import './style.css';
import { fetchData, postData } from '../../../services/HomeService';
import { CaretDownOutlined, CaretUpOutlined, MoreOutlined} from '@ant-design/icons';
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';
import ModalAdvancedFilterComponent from './ModalAdvancedFilterComponent';
import SortBySelectPanel from '../../common/SortBySelectPanel';
import { BASE_IMG, FILTER_OPTIONS } from '../../../constants';
import RequestNudgeComponent from '../../nudge/RequestNudgeComponent';
import { handleVendorAction } from '../../../services/RemoveVendor';
import { showErrorMessage, downloadCSVFile } from '../../../utils';
import ListViewComponent from './ListViewComponent';

const { Search } = Input;

export default function VendorLists({ selectedParent, selectedChild, AvatarColor }) {
    const [vendorData, setVendorData] = useState([]);

    const [filter, setFilter] = useState('');
    const [pagination, setPagination] = useState({ page: 1, limit: 10, total: 0 });
    const [sorting, setSorting] = useState({ field: 'updatedAt', order: 'desc' });
    const [popoverCategory, setPopoverCategory] = useState(false);
    const [isAdvancedFilterModalVisible, setIsAdvancedFilterModalVisible] = useState(false);
    const [resetKey, setResetKey] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [vendorSelections, setVendorSelections] = useState({});
    const [showRemoveModal , setShowRemoveModal] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState([]);
    const [searchkey, setSearchKey] = useState('');
    const [overallData, setOverallData] = useState({});
    const [showExportModal, setShowExportModal] = useState(false);
    const [projection, setProjection] = useState([]);
    const [filterIcon, setFilterIcon] = useState({
        advanceFilter: false,
        categoryFilter: false,
      });

    const user = useSelector((state) => state.user); 

    const fetchItems = async () => {
        if (!user.org) {
            message.warning('Please complete mandatory steps before accessing this page');
            return;
        }
        const queryString = `/relations/source/${user.org}/vendor?page=${pagination.page}&limit=${pagination.limit}&sort=${sorting.field}-${sorting.order}&filter=${filter}&search=${searchkey}`;
        const data = await fetchData(queryString);
        setVendorData(data.data);
        setPagination({ ...pagination, total: data.total });
    };

    useEffect(() => {
        fetchItems();
    }, [pagination.page, pagination.limit, pagination.total, sorting.field, sorting.order, filter, searchkey]);

    const onSearch = (value) => {console.log(value);
        setSearchKey(value);
    }
    const closeExportModal = () => {
        setShowExportModal(false);
    }

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, page, limit: pageSize });
        setSelectAll(false);
        setVendorSelections({}) 
    };

    const handleSortChange = (field, order='asc') => {
        setSorting({ field, order });
        setSelectAll(false);
        setVendorSelections({}) 
    };

    const handleResetFilters = () => {
        setFilter('');
        setSorting({ field: 'updatedAt', order: 'desc' });
        setResetKey((prevKey) => prevKey + 1);
        setFilterIcon({ advanceFilter: false, categoryFilter: false})
      };

      const handleFilterChange = (field, operator, value, source) => {
        if (field && operator && value) {
          const filterValue = `${field}-${operator}-${Array.isArray(value) ? value.join(','): value}`
          setFilter(filterValue);

          if (source === 'query') {
            setFilterIcon({advanceFilter: true });
          } else if (source  === 'column') {
            if(field === 'category') setFilterIcon({ categoryFilter: true });
          }
        } else {
          console.log("Some error has happened");
        }
        setSelectAll(false);
        setVendorSelections({}) 
      };

    const handleCategoryFilterChange = () => {
        setPopoverCategory(!popoverCategory)
    };

    const handleAdvancedFilterClick = () => {
        setIsAdvancedFilterModalVisible(true);
    };

    const CloseFilterModal = () => {
        setIsAdvancedFilterModalVisible(false);
    };

    const handleSelectAllToggle = () => {
        let updatedVendorSelections = {};
        let selectedIds = [];

        if (selectAll) {
            // If "Select All" checkbox is checked, deselect all.
            updatedVendorSelections = {};
            selectedIds = [];
        } else {
            // If "Select All" checkbox is not checked, select all.
            vendorData.forEach(vendor => {
                updatedVendorSelections[vendor._id] = true;
                selectedIds.push(vendor._id);
            })
        }

        setVendorSelections(updatedVendorSelections);
        setSelectAll(!selectAll);
        setSelectedVendorId(selectedIds);
    };
    
    const openAllRemoveVendorModal =() => {
        setShowRemoveModal(true)
    }

    const closeAllRemoveVendorModal =() => {
        setShowRemoveModal(false)
    }

    const handleSelectedVendorAction = async (id, action) => {
        try {
            const { success } = await handleVendorAction(id, action);
            if (success) {
                const updatedVendorData = vendorData.filter(vendor => !id.includes(vendor._id));
                const updatedVendorSelections = { ...vendorSelections };
                id.forEach(removedId => delete updatedVendorSelections[removedId]);
                const updatedPagination = {
                    ...pagination,
                    total: pagination.total - id.length
                };
                setVendorData(updatedVendorData);
                setVendorSelections(updatedVendorSelections);
                setPagination(updatedPagination);
            }
        } catch (error) {
            showErrorMessage(error.message);
        } finally {
            setShowRemoveModal(false);
        }
    };

    const fetchCategory = async () => {
        const response = await fetchData('/partners/register/vendor?filter=tag_category')
        const tagKey = response?.tagKey ? response.tagKey : 'tag_category';
        const categoryData = response?.data?.[tagKey];
        if (categoryData && categoryData.fieldOptions) {
            FILTER_OPTIONS['category'] = categoryData.fieldOptions
        }
        const overall = response?.overall;
        const overallData = [];
        Object.keys(overall).forEach(key => {
            overallData.push(overall[key].fieldLabel);
        })
        console.log('overallData', overallData);
        setOverallData(overallData);
      };
      const onChangeFieldList = (checkedValues) => {
        console.log('checked = ', checkedValues);
        setProjection(checkedValues);
      };

    useEffect(() => {
        fetchCategory();
      }, []);
    const  handleBulkExport = () => {
        //TODO: Implement bulk export call apifetch all selected vendor ids and export in csv
        const selectedIds = Object.keys(vendorSelections);
        const selectedPids = vendorData.filter(vendor => selectedIds.includes(vendor._id)).map(vendor => vendor.sinkPartnerId);
        const queryString = `/partners/getall/${user.org}`;
        postData(queryString, { ids: selectedPids, projections: projection })
            .then((response) => {
                if (response.success) {
                    const fileName = 'vendor_export.csv';
                    const success = downloadCSVFile(response.data, fileName);
                    if (success) {
                        setShowExportModal(false);
                        message.success('Exported successfully');
                    }
                } else {
                    message.error('Export failed');
                }
            })
            .catch((error) => {
                console.error('Export error:', error);
                message.error('Export failed');
            });
    }

    return (
        <main className="Home_container">
            <section className="Home_firstContainer">
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} />
                <div className="Home_heading">
                    <h1> All Added Vendors</h1>
                </div>
                <div className='VendorLists_filter'>
                    <div className="VendorLists_search">
                        <Search
                            placeholder="Find a Vendor"
                            allowClear
                            enterButton="Search"
                            onSearch={onSearch}
                        />
                    </div>
                    <div className='VendorList_filterSelect'>
                      <SortBySelectPanel  key={`column-${resetKey}`} properties={FILTER_OPTIONS['column']} handleSortSelection={handleSortChange}/>
                    </div>
                    <Button onClick={handleAdvancedFilterClick}>Advanced Filter  {filterIcon.advanceFilter && <div className="Dashboard_notification" />}<img src={`${BASE_IMG}/dashboard-img/dashboard_icons/white_category.svg`} alt=''/>  </Button>
                    <div className='VendorList_reset' onClick={handleResetFilters}>Reset Filters<img src={`${BASE_IMG}/dashboard-img/dashboard_icons/reset.svg`} alt='reset' /></div> 
                </div>
            </section>
            <section className="VendorLists_adding">
                {pagination.total > 1 && (
                    <section className='ListComponent_sorter'>
                        <div className='ListComponent_radio'><Checkbox onClick={handleSelectAllToggle} checked={selectAll}>Select all</Checkbox></div>
                        <div className='ListComponent_radioOption'>
                            <p className={Object.keys(vendorSelections).length > 1 ? "" : "ListComponent_disabled"} onClick={() => Object.keys(vendorSelections).length > 1 && openAllRemoveVendorModal()}>Remove All</p>
                            <p className={Object.keys(vendorSelections).length > 1 ? "" : "ListComponent_disabled"} onClick={()=>  Object.keys(vendorSelections).length > 1 && setShowExportModal(true)} >Export All</p>
                            {/* TODO: Remove comment when extra options needed in popover */}
                            {/* <p>Assign Category</p>
                            <Popover
                                content={
                                    <div className="list_popup">
                                        <span>Export</span>
                                        <span onClick={openAllRemoveVendorModal}>Remove</span>
                                    </div>
                                }
                                overlayClassName="list_popover"
                                trigger="click"
                                placement="bottomLeft">
                                    <MoreOutlined className="ListComponent_moreIcon"/>
                            </Popover> */}
                        </div>
                    </section>
                )}
                <section className="ListComponent_sorter">
                    <div className='ListComponent_name'>
                        <p>Name</p>
                        <div className='ListComponent_sorterIcon'>
                            <CaretUpOutlined onClick={() => handleSortChange('sinkPartnerName', 'asc')} />
                            <CaretDownOutlined onClick={() => handleSortChange('sinkPartnerName', 'desc')} />
                        </div>
                    </div>
                    <div className='ListComponent_category'>
                        <Popover
                            content={ <div className='ListComponent_categoryData'>
                                  <Radio.Group >
                                  <Space direction="vertical">
                                    {FILTER_OPTIONS['category']?.map((option, index) => (
                                        <Radio key={index} value={option.value} name={option.label} onChange={() => handleFilterChange('category', 'eq', option.label, 'column')}>
                                            {option.label}
                                        </Radio>
                                    ))}
                                  </Space>
                                </Radio.Group>
                                </div>
                            }
                            trigger="click"
                            overlayClassName="popover-no-arrow category-arrow"
                            placement="bottomRight"
                            open={popoverCategory}
                            onOpenChange={handleCategoryFilterChange}> 
                                <p className='ListComponent_categoryIcon'>Category <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/category.svg`} alt='' /> {filterIcon.categoryFilter && <div className="Dashboard_notification" style={{right: '-5px', top: '14px'}} />}</p>
                        </Popover>
                    </div>
                    <p>Actions</p>
                </section>
                <ListViewComponent viewType={'vendor'} vendorData={vendorData} AvatarColor={AvatarColor} setVendorData={setVendorData} setPagination={setPagination} pagination={pagination} categoriesList={FILTER_OPTIONS['category']} selectAll={selectAll} setSelectAll={setSelectAll} vendorSelections={vendorSelections} setVendorSelections={setVendorSelections} setAllSelectedVendorId={setSelectedVendorId} />
                <div className="ListComponent_container" style={{ width: '100%' }}>
                    <Pagination
                        total={pagination.total}
                        current={pagination.page}
                        pageSize={pagination.limit}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => `Total ${total} items`}
                        onChange={handlePageChange}
                        onShowSizeChange={(current, size) => {
                            setPagination({ ...pagination, page: current, limit: size });
                        }}
                    />
                </div>
            </section>
            <ModalAdvancedFilterComponent 
                isVisible={isAdvancedFilterModalVisible} 
                onClose={CloseFilterModal}
                onApplyFilters={handleFilterChange}
                filterOptions={FILTER_OPTIONS} 
                key={`query-${resetKey}`}
            />

            <RequestNudgeComponent
                type={'MultipleRemoveVendor'}
                action={'MultipleRemove'}
                data={selectedVendorId}
                isVisible={showRemoveModal}
                onClose={closeAllRemoveVendorModal}
                handleNudgeSubmit={handleSelectedVendorAction}
            />
            {
                showExportModal && (    
                    <Modal 
                        open={showExportModal}
                        onCancel={closeExportModal}
                        title="Export Vendors"
                        footer={<Button key="done" type="primary" onClick={handleBulkExport}>Export</Button>}
                        className='ExportModal_container'
                    >
                        <div className='ExportModal_subContainer'>
                            <p>Select keys for export</p>
                            <Checkbox.Group
                                style={{
                                width: '100%',
                                }}
                                onChange={onChangeFieldList} >
                            <Row gutter={[16, 16]}>
                                        {overallData.map((key, index) => (
                                            <Col span={12} key={index}>
                                                <Checkbox value={key}>{key}</Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                            </Checkbox.Group>
                        </div>
                    </Modal>
                )
            }

        </main>
    );
}
