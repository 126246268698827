import { Avatar, Modal } from 'antd'
import React , { useEffect, useState } from 'react'
import { getHistory } from './quotationService'

export default function ModalHistoryComponent({isVisible, onClose, AvatarColor, name, id}) {
  const [history, setHistory] = useState([])
  
  useEffect(() => {
    getHistory(id).then((data) => {
      setHistory(data)
    }).catch((error) => {
      console.error('Error fetching history:', error)
    })
  }, [id])

  return (
      <Modal
        visible={isVisible}
        title="History"
        onCancel={onClose}
        footer={null}
        className="Quotes_modal Modal_ht"
      >
        <div className="Quotation_history">
          {history && history.length > 0 ? (
            history.map((item, index) => (
              <div className='Quotation_subHistory' key={index}>
                <div className='Quotation_contantH'>
                  <Avatar style={AvatarColor?.[index]?.style}>{name?.charAt(0)}</Avatar>
                  <p>{item.text}</p>
                </div>
                <span className='Quotation_subHistoryDate'>
                  {new Date(item.createdAt).toLocaleString()}
                </span>
              </div>
            ))
          ) : (
            <p>History will appear here.</p>
          )}
        </div>
      </Modal>
  )
}
