import _ from 'lodash';
import { Button, Modal, Switch } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import FormRegistration from '../../common/FormRegistration'
import { fetchData } from '../../../services/HomeService';
import { updateApiCall } from '../../../services/RegistrationService';
import { checkEmptyFields, checkRegexInValidation, showErrorMessage, showSuccessMessage, showLoader, formatCleanData } from '../../../utils';

const SOURCE_ENTITY = 'partners';

export default function ModalEditDetailsComponent({isVisible, onClose, modalData, registerType='vendor'}) {
  const [formTemplateData, setFormTemplateData] = useState({});
  const [formPrefillData, setFormPrefillData] = useState(null);
  const [disabledFields, setDisabledFields] = useState({});
  const [disabledSections, setDisabledSections] = useState({});
  const [localLoader , setLocalLoader] = useState(true);
  const [dataValidationSwitchChecked, setDataValidationSwitchChecked] = useState(true);

  const fetchItems = async (pid) => {
    const data = pid? await fetchData(`/partners/${pid}`): null;
    if (data && !_.isEmpty(data)) {
      setFormPrefillData(data);
      updateDependencies(data);
    }    
  };

    useEffect(() => {
      if (modalData) {
          fetchItems(modalData);
      }
    }, [modalData]);
   const updateDependencies = (data) => {
    const disabledFields = {};
    const disabledSections = {};
    // Correctly flatten the sections into a single array
    const flatData = []
    Object.keys(data.sections).forEach(sectionKey => {
        const section = data.sections[sectionKey];
        flatData.push(...section);
    });
    // Iterate over the flattened array of fields
    flatData.forEach(fieldData => {
        const isFieldDisabled = fieldData.fieldStyle?.disabled;
        const isFieldHidden = fieldData.fieldStyle?.hide;
        if (isFieldDisabled) {
            disabledFields[fieldData.fieldKey] = isFieldDisabled;
        }
        if (isFieldHidden) {
            disabledSections[fieldData.fieldKey] = isFieldHidden;
        }
    });
    setDisabledFields(disabledFields);
    setDisabledSections(disabledSections);
    setLocalLoader(false);
}
    useEffect(() => {
      const fetchTemplate = () => {
        fetchData(`/partners/register/${registerType}?pid=${modalData}`)
            .then((resp) => {
                setFormTemplateData(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
        };
        fetchTemplate();
    }, []);

    const handleInputFieldChange = async (index, formData) => {
        setFormTemplateData(formData);
    };
    const handleDisabledFields = (index, fields) => {
      setDisabledFields({ ...disabledFields, ...fields })
  };
  const handleDisabledSections = (index, section) => {
      setDisabledSections({ ...disabledSections, ...section })
  };
    const handleEditModalClick = async (pid) => {
      console.log(disabledFields, disabledSections)

      if(dataValidationSwitchChecked){
        if (checkEmptyFields(formTemplateData, disabledFields, disabledSections)) {
          showErrorMessage('Some required fields are missing')
          return;
      }

      if (checkRegexInValidation(formTemplateData, disabledFields, disabledSections)) {
          showErrorMessage('Some fields have invalid inputs')
          return
      }
      }
     
      const requestData =  Object.entries(formTemplateData).reduce((acc, [key, value]) => {
          if (value.fieldStyle && value.fieldStyle.bypassCleaning) {
              acc[key] = value.fieldValue;
          } else {
              acc[key] = formatCleanData(value.fieldValue);
          }
          return acc;
      }, {});
        const { success, message:msg='' } = await updateApiCall({requestData, source_entity: SOURCE_ENTITY, pid, registerType});
        if (success) {
          showSuccessMessage(msg || 'Information updated successfully!') ;
          onClose();
      } else {
        showErrorMessage(msg || 'Failed to update information');
      }
      
  }

  return (
    <Modal
      open={isVisible}
      onCancel={onClose} 
      title="Edit Vendor Details"
      footer={<Button key="done" type="primary" onClick={() => handleEditModalClick(modalData)}>Save</Button>}
      className='EditModal_container' style={{position: "relative"}}>
        <div className='EditModal_subContainer mandatory'>
            <section className='Toggler_main'>
                <div className='Toggler_containerLeft'>
                {/* <span>Detailed Form <Switch checked={detailedFormSwitchChecked} onChange={(checked) => setDetailedFormSwitchChecked(checked)} /></span> */}
                    <span>Data Validation <Switch checked={dataValidationSwitchChecked} onChange={(checked) => setDataValidationSwitchChecked(checked)} /></span>
                </div>
                <span>* Mandatory fields</span>
            </section>
            {localLoader ?
              <div style={{color: '#4800FF', height: '400px'}}>
                <div style={{textAlign: "center", fontSize: '20px'}}>
                  <LoadingOutlined />
                  <span>Loading....</span>
                </div>
              </div> :
            <section className='EditModal_form'>
            <FormRegistration 
              formTemplateData={formTemplateData} 
              onFieldChange={handleInputFieldChange} 
              setDisabledFields={handleDisabledFields}
              setDisabledSections={handleDisabledSections}
              disabledFields={disabledFields}
              disabledSections={disabledSections}
              formPrefillData={formPrefillData} 
              formDataValidation={dataValidationSwitchChecked} formFormatLong={true}
              partnerID={modalData} 
              formStyle={{ maxWidth: '260px', width: '100vw' }} />
    
            </section>
          }
        </div>
    </Modal>
  )
}
