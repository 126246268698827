import { Button, Checkbox } from "antd";
import React, { useEffect, useState }  from "react";
import { getIcon } from '../../utils';
import _ from 'lodash'
import { CheckOutlined } from "@ant-design/icons";
import { BASE_IMG } from "../../constants";

export default function PaywallCard({
  plan,
  isAnnual,
  totalCost,
  selectedKeys,
  handleCheckboxChange,
  handleUnlockButtonClick,
}) {

  const [isUnlockButtonDisabled, setIsUnlockButtonDisabled] = useState(false);

  useEffect(() => {
    if(selectedKeys) setIsUnlockButtonDisabled(!plan.topPlan && selectedKeys.length === plan.options.length || selectedKeys.length === 0) 
    else setIsUnlockButtonDisabled(false)
  }, [selectedKeys]);

  if(_.isEmpty(plan)) return <></>

  return (
    <section className={`Pw_card ${plan.topPlan ? "Pw_card-2" : "Pw_card-1"}`}
    style={plan.topPlan ? {
        border: `1.5px solid ${plan.metaData?.styles?.foreGroundColor}`
      } : {}}>
      <div className="Pw_cardTop">
        <span>{plan ? plan.name : ""}</span>
        <div className="Pw_cardTop-2">
        <h2 style={{ color: plan.metaData?.styles?.foreGroundColor }}>Rs. {plan ? Math.floor(totalCost / 100) : 0}</h2>
          <span>
            <p>selected features / {isAnnual ? "month" : "week"},</p>
            <p>{(plan ? plan.mode: "").replace( '-', ' ')}</p>
          </span>
        </div>
      </div>
      <div className="Pw_cardBottom">
        <div className="Pw_cardBottom-1">
          <div className="Pw_cardPoints">
            {plan.topPlan && <p className="Pw_planCardText" style={{ backgroundColor: plan.metaData?.styles?.foreGroundColor }}>Recommended</p>}
            {plan.metaData
              ? plan.metaData.ctaTexts.map((cta, ctaIndex) => (
                  <div className="Pw_cardEachpoint" key={ctaIndex}>
                    {cta && cta.icon && getIcon(cta.icon)}
                    <p>{cta && cta.label}</p>
                  </div>
                ))
              : null}
          </div>
          <div className={plan.topPlan ? "Pw_cardPlan-1" : "Pw_cardPlan"}>
            <span>{plan.metaData ? plan.metaData.titleText : ""}</span>
            <div className="Pw_cardTick">
              {plan.options &&
                plan.options.map((option, optionIndex) => (
                  <div className="Pw_cardEachTick" key={optionIndex}>
                  {plan.topPlan ? (
                      <div className="Pw_topCard">
                        <CheckOutlined />
                        {option.description}
                      </div>
                    ) : (
                      <Checkbox
                        checked={selectedKeys.includes(option.key)}
                        onChange={() => handleCheckboxChange(option.key)}
                      >
                        {option.description}
                      </Checkbox>
                    )}

                    {plan.topPlan ? (
                      <p>
                        <span className="Pw_price">
                          {option.discount > 0
                            ? `₹${Math.floor(
                                (option.cost + option.discount) / 100
                              )}`
                            : ""}
                        </span>
                        ₹{option ? Math.floor(option.cost / 100) : 0}
                      </p>
                    ) : (
                      <p>+ ₹{option ? Math.floor(option.cost / 100) : 0}</p>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className={`Pw_cardBottom-2 ${
            plan.topPlan ? "Pw_premiumBtn" : "Pw_basicBtn"
          }`}
        >
          <Button
            className={ isUnlockButtonDisabled
                ? "disabled-Unlockbutton"
                : ""
            }
            style={plan.topPlan 
            ? { border: `1px solid ${plan.metaData?.styles?.foreGroundColor}`, backgroundColor: plan.metaData?.styles?.foreGroundColor,} 
            : { border: `1px solid ${plan.metaData?.styles?.foreGroundColor}`, backgroundColor: plan.metaData?.styles?.backGroundColor, color : plan.metaData?.styles?.foreGroundColor}}
            disabled={isUnlockButtonDisabled}
            onClick={() => handleUnlockButtonClick(plan, selectedKeys)}
          >
            Unlock Now
          </Button>
          <div className="Pw_payIcon">
            <img src={`${BASE_IMG}/paywall_images/upi.svg`} alt="" />
            <img src={`${BASE_IMG}/paywall_images/credit-card.svg`} alt="" />
          </div>
        </div>
      </div>
    </section>

  );
}
