import React, { useState } from 'react'
import { Button, Input } from 'antd';
import './FeatureFeedbackComponent.css';
import { postData } from '../../services/HomeService';
import { showErrorMessage, showLoader, showSuccessMessage } from '../../utils';
import { BASE_IMG } from '../../constants';

export default function FeatureFeedbackComponent({name}) {
    const [comment, setComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        if (!comment || !name) {
          showErrorMessage('Please give your valuable comments');
          return;
        } 
        const requestData = {
          name: name,
          comment: comment,
        };
        setIsLoading(true);
        
        try {
          const response = await postData('/notifications/feature-request', requestData);
          if (response.success) {
            setComment('');
            showSuccessMessage('Request successfully submitted.');
          } else {
            showErrorMessage(response.message);
          }
        } catch (error) {
          showErrorMessage(error.message);
        } finally {
          setIsLoading(false);
        }
      };
    
    return (
        <section className='FeatureFeedBack_Container'>
            <div className='FeatureFeedBack_img'>
                <img src={`${BASE_IMG}/Feature_3.svg`} alt='' />
            </div>
            <div className='FeatureFeedBack_headingConatiner'>
                <h1>Want to get your hands on this feature?</h1>
                <h3>Join the list. Raise a demand now!</h3>
            </div>
            <Input.TextArea placeholder='Tell us how this feature might be helpful to you' value={comment} onChange={(e) => setComment(e.target.value)} autoSize={{ minRows: 4, maxRows: 6 }} />
            <Button onClick={handleSubmit} >Submit</Button>
            {isLoading && showLoader("Submitting")} 
        </section>
    )
}
