import { BASE_URL } from "../constants";

export const handleLoginSubmit = async (e, authType, username, password) => {
  e.preventDefault();

  const pathUrl = "home";
  let endpoint;
  switch (authType) {
    case 'google':
    case 'microsoft':
    case 'linkedin':
      endpoint = `${BASE_URL}/users/auth/url`;
      break;
    case 'local':
      endpoint = `${BASE_URL}/users/auth/login`;
      break;
    default:
      return;
  }
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': navigator.userAgent
      },
      body: JSON.stringify({
        email: username,
        password: password,
        authType: authType,
        pathUrl: pathUrl
      })
    });
    const resp = await response.json();
    if (authType !== 'local') {
      window.location.href = resp.url;
    } else {
      if (resp.success) {
        return { success: true, data: resp.data };
      } else {
        return { success: false, message: resp.message };
      }
    }
  } catch (error) {
    console.error(error.message);
    return { success: false };
  }
};

export const handleSkipLogin = async () => {
  try {
    const response = await fetch(`${BASE_URL}/users/skip-login`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': navigator.userAgent
      }
    });
    const resp = await response.json();
    if (resp.success) {
      return { success: true, data: resp.data };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    console.error(error.message);
    return { success: false };
  }
};
export const handleSignUpSubmit = async (name, username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/users/register/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': navigator.userAgent
      },
      body: JSON.stringify({
        name: name,
        email: username,
        password: password,
      })
    });
    const resp = await response.json();
    if (resp.success) {
      return { success: true, data: resp.data, message: resp.message };
    } else {
      return { success: false, message: resp.message  };
    }
  } catch (error) {
    console.error(error.message);
    return { success: false };
  }
};
