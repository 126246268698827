import React, { useEffect, useState } from 'react'
import { Select, Radio, Space } from 'antd';
import _ from 'lodash';

const { Option } = Select;

export default function RadioSelect({ options, handleSelect, setOption }) {
    
  const [selectedOption, setSelectedOption] = useState(setOption);

  useEffect(() => {
    setSelectedOption(setOption)
  }, [setOption])

  const handleOptionChange = (optionValue) => {
      const _selectedOption = options.find(op => op.value === optionValue)
      setSelectedOption(_selectedOption)
      handleSelect(_selectedOption);
  };

  return (
    <Space>
      <Select placeholder={'Select a category'} value={selectedOption.section} disabled={!_.isEmpty(setOption)}>
        {options.map((option, index) => (
          <Option key={index} value={option.value}>
            <Radio onChange={() => handleOptionChange(option.value)} checked={selectedOption.value === option.value}>
              {option.label}
            </Radio>
          </Option>
        ))}
      </Select>
    </Space>
  )
}