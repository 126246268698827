import React, { useEffect, useState } from "react";
import _ from 'lodash'

import Navbar from "../common/Navbar";
import "./VerificationComponent.css";
import { useLocation, useNavigate } from "react-router-dom";

import { fetchData, postData } from "../../services/HomeService";
import { DEFAULT_REDIRECT_ROUTE, COMPONENT_ROUTE_MAP} from "../../constants";
import { getIcon, showSuccessMessage, showErrorMessage, showLoader } from "../../utils";

import { useDispatch } from "react-redux";
import { clearUser } from "../../helpers/userSlice";
import UserStepTemplates from "./UserStepTemplates";

export default function VerificationComponent() {
  const [isLoading, setIsLoading] = useState(false); 
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("userType");
  const userStep = queryParams.get("userStep");
  const userVerified = queryParams.get("userVerified") === 'true';

  const [items, setItems] = useState({
    popup: [],
    profile: [],
  });

  const fetchItems = async (type) => {
    if(!type) return
    const resp = await fetchData(`/options/popup,profile?orgType=${type}`);
    if (resp && !_.isEmpty(resp.data)) {
      setItems(resp.data);
    }
  };

  useEffect(() => {
    fetchItems(userType);
  }, [userType]);

  useEffect(() => {
    if(!userType) navigate('/login?type=selection')
    if(userVerified && userType === 'vendor') navigate(`${DEFAULT_REDIRECT_ROUTE}/${COMPONENT_ROUTE_MAP['VendorSubscriptionPlans']}`);
    if(userVerified && userType === 'enterprise') navigate(`${DEFAULT_REDIRECT_ROUTE}/${COMPONENT_ROUTE_MAP['DashboardOverall']}`);
  }, [userVerified]);

  const handleRequestButtonClick = async () => {
    try {
      if(!userType) return
      if(!userStep) {
        navigate('/');
        return
      }
      setIsLoading(true);

      let response = {}
      if (userStep === 'email') response = await postData("/notifications/request-verification", { 	"orgType" : userType });
      else if (userStep === 'account') response = await fetchData("/users/whitelist/request");
      
      if (response.success) {
        showSuccessMessage(response.message || "Your request sent successfully!!");
        dispatch(clearUser());
        navigate('/login')
      } else {
        showErrorMessage(response.message);
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar
        showSettings={true}
        items={items.popup}
        getIcon={getIcon}
        profile={items.profile}
      />
      <div className="Uv_container">
        <UserStepTemplates userType={userType} userStep={userStep} handleSendButton={handleRequestButtonClick}/>
        {isLoading && showLoader("Sending")} 
      </div>
    </>
  );
}
