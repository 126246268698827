export const MAX_WIDTH_SMALL_LOGIN = 767;
export const MAX_WIDTH_SMALL_SCREEN = 500;
export const DEFAULT_REDIRECT_ROUTE = '/home';
export const HOMEPAGE_REDIRECT_ROUTE = 'https://www.raze.co.in';
export const COPY_MESSAGE = 'Copied to clipboard';
export const BASE_URL = 'https://api.raze.co.in';
export const BASE_IMG = 'https://dg53pzd6emty4.cloudfront.net/business'
export const MAX_FIELD_PER_ROW = 4;

export const COMPONENT_ROUTE_MAP = {
    DashboardOverall: 'dashboard-overall',
    VendorOnboard: 'vendor-add',
    VendorLists: 'vendor-list',
    VendorProfile: 'vendor-profile',
    VendorRank: 'vendor-rank',
    VendorDiscover: 'vendor-discover',
    AccountInfo: 'account-info',
    VendorSubscriptionPlans: 'subscription-vendor',
    VendorQuotations: 'quotations',
    Vendors: 'vendors',
    VendorApprovals: 'vendor-approvals',
}
export const PROFILE_CTA_MAP = {
    VERIFIED_VENDOR: 'Vendor has completed the verification process, all the following info is marked as correct',
    NOT_VERIFIED_VENDOR: "Verification is pending from Vendor's end"
}
export const ACCOUNT_CTA_MAP = {
     ACCOUNT_VERIFICATION: 'Important: Some account information has been sourced in advance from different partners, please verify and make rectifications wherever required',
}

export const ERROR_CTA_MAP = {
    FILE_UPLOAD_ERROR: 'File upload failed. Please try again.',
    FILE_TYPE_ERROR: 'This file type is not supported.'
}
export const COMPONENT_DISPLAY_TEXT = {
    PROFILE_BREADCRUMB: 'Vendor Profile'
}
export const ONBOARDING_COMPLETED_STATUS = 'onboarding-completed'
export const CREATION_COMPLETED_STATUS = 'creation-completed'

export const LINK_URL_NOTES             = 'notes-vendor'
export const LINK_URL_PAYWALL           = 'pricing-enterprise'
export const LINK_URL_QUOTES            = 'quotes-vendor'
export const LINK_TYPE_MODAL            = 'modal'
export const LINK_EDIT_DETAILS          = 'edit-details'
export const LINK_ATTACHMENTS           = 'attachment-vendor'
export const LINK_EMAIL_VERIFICATION    = 'verify-vendor'
export const LINK_URL_VENDOR_PROFILE    = 'vendor-profile'
export const LINK_TYPE_ROUTE_INTERNAL   = 'internal'
export const LINK_API_CALL              = 'api'

export const MODAL_TITLES = {
    note: {
        ADD: 'Add a note',
        EDIT: 'Edit a note',
        DELETE: 'Delete a note'
    }
}

export const SPIN_TEXT_MAP = {
    VENDOR_RESGISTRATION_SUCCESS: 'Vendor successfully added',
    VENDOR_RESGISTRATION_ERROR: 'Something went wrong, Please try again'
}

export const LINK_SEPERATOR = '\n Link:- \n';
/* eslint-disable no-useless-escape */
export const LINK_REGEX = /((?:(?:https?|ftp):\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s]*)?)/gi;

export const FILTER_OPTIONS = {
    column: [
        { value: 'sinkPartnerName', label: 'Name' },
        { value: 'category', label: 'Category' },
        { value: 'status', label: 'Status' },
        { value: 'state', label: 'State' },
    ],
    operator: [
        { value: 'eq', label: '=' },
        { value: 'neq', label: '!=' },
        { value: 'in', label: 'in' },
        { value: 'nin', label: 'not in' },
    ],
    category: [],
    status: [
      { value: 'started', label: 'Started' },
      { value: 'pending', label: 'Pending' },
      { value: 'completed', label: 'Completed' },
    ],
    state: [
      { value: 'creation', label: 'Creation' },
      { value: 'invitation', label: 'Invitation' },
      { value: 'verification', label: 'Verification' },
      { value: 'onboarding', label: 'Onboarding' },
    ],
};