import { Popover } from 'antd';
import React from 'react'
import { getIcon } from '../../utils';

export default function StatusPopoverComponent({ image, icon, color, popoverContent, popoverVisible, setPopoverVisible, className }) {
  return (
    <Popover
    content={popoverContent}
    trigger="click"
    overlayClassName={className}
    placement="bottomLeft"
    open={popoverVisible}
    onOpenChange={setPopoverVisible}
  > 
    {icon ? (
        getIcon(icon, color)
      ) : (
        <img src={image} alt='' style={{ cursor: "pointer", width: '20px' }} />
      )}
  </Popover>
);
};

