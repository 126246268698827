import { DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import React  from 'react'
import { useState } from 'react';
import { capitalizeFirstLetter, getStatusText } from '../../../utils';
import {showSuccessMessage , showErrorMessage} from '../../../utils/index'

export default function TableComponent({type,type2, quoteData, actionAPI, items, itemsStatus, setIsSaving, isSaving,setUpdateClicked, updateClicked, submittedItems, handleRestore, removedItems, saveAllItems, handleRemove,handleDeleteForItem, openHistoryModal, handleInputChange, handleDateChange, handleItemStatus}) {
  const [localItems, setLocalItems] = useState(items)
  const [emptyFields, setEmptyFields] = useState({ items: [], removedItems: [] });
  const [activeIndex, setActiveIndex] = useState(null);
  const handleUpdateClick = (index) => {
    setUpdateClicked(true);
    setActiveIndex(index);
    // setTimeout(() => {
    //   setActiveIndex(null); // Reset after a delay to remove the active class
    // }, 500);
  };

  const parseDate = (date) => {
    if (!date) return null;

    if (dayjs.isDayjs(date)) {
      return date;
    }

    if (dayjs(date).isValid()) {
      return dayjs(date);
    }
  
    const formats = [
      "DD-MM-YYYY",
      "DD/MM/YYYY",
      "YYYY-MM-DD",
      "YYYY/MM/DD",
      "MM-DD-YYYY",
      "MM/DD/YYYY",
      "YYYY-DD-MM",
      "YYYY/DD/MM",
      "DD MMM YYYY",
      "DD MMMM YYYY",
      "DD MMM YY",
      "DD MMMM YY",
      "MMM DD, YYYY",
      "MMMM DD, YYYY",
      "MMM DD YYYY",
      "MMMM DD YYYY",
      "MMM DD, YY",
      "MMMM DD, YY",
    ];
  
    let parsedDate = null;
  
    for (let format of formats) {
      parsedDate = dayjs(date, format, true);
      if (parsedDate.isValid()) break;
    }

    return parsedDate.isValid() ? parsedDate : null;
  };

 const checkEmptyFields = (itemList, key) => {
  let newEmptyFields = [];
  const fullBlankFields = [];
  itemList.forEach((item, index) => {
    const fields = [item.availableQuantity, item.supplyDate, item.quotedPrice];
    const filledFields = fields.filter(field => field);
    if (filledFields.length > 0 && filledFields.length < fields.length) {
      newEmptyFields.push(index);
    }
    if (fields.every(field => !field)) {
      fullBlankFields.push(index);
    }
  });
  setEmptyFields(prevState => ({
    ...prevState,
    [key]: newEmptyFields
  }));
  return newEmptyFields.length === 0  && fullBlankFields.length !== itemList.length;
};

const handleSaveAllClick = () => {
  const value = checkEmptyFields(items, 'items');
  if (value) {
    saveAllItems();
  } else {
    showErrorMessage("Please complete all fields for each quote before saving.");
  }
};
  
  const handleRestoreClick = () => {
    if (checkEmptyFields(removedItems, 'removedItems')) {
      handleRestore();
    }
    else{
      showErrorMessage("Please complete atleast one before saving.");
    }
  };

  return (

    <>
    {type === 'viewData'&& quoteData && 
      <section className="Quotation_section">
          <div className="Quotation_subtitle">
            <span>Quotation Requested</span>
            {!type2 && <span onClick={openHistoryModal}>View history</span>}
          </div>
          <table className="Quotation_table">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Item</th>
                <th>Quantity</th>
                <th>Due Date</th>
                <th>Min Budget</th>
                <th>Max Budget</th>
                <th>Place of Supply</th>
                <th>Supply Date</th>
                {!type2 && <th>Status</th>}
              </tr>
            </thead>
            <tbody>
              {quoteData?.items?.map((item, index) =>{
                const currentStatus = itemsStatus?.items?.length > 0 && index < itemsStatus.items.length ? itemsStatus.items[index].itemStatus : '';
                return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.quantity} {item.unit}</td>
                  <td>{item.dueDate ? dayjs(item.dueDate).format("DD/MM/YYYY") : ""}</td>
                  <td>{item.budget?.min}</td>
                  <td>{item.budget?.max}</td>
                  <td>{item.placeOfSupply.join(', ')}</td>
                  <td>{item.supplyDate ? dayjs(item.supplyDate).format("DD/MM/YYYY") : ""}</td>
                  {!type2 &&
                    <td style={getStatusText(currentStatus)}>
                    {capitalizeFirstLetter(currentStatus)}
                  </td>
                  }
                </tr>
              )})}
            </tbody>

          </table>
        </section>
    }

    {type2 === 'ListApprovals'&& items &&
      <section className="Quotation_section">
          <div className="Quotation_subtitle">
            <span>Quotation Accepted</span>
            {!type2 && <span onClick={openHistoryModal}>View history</span>}
          </div>
          <table className="Quotation_table">
            <thead>
              <tr>
              <th>Sr.</th>
              <th>Item</th>
              <th>Available Quantity</th>
              <th>Supply Date</th>
              <th>Quoted Price</th>
              <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {localItems?.map((item, index) => (
               item.itemStatus !== '' ?
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  {item.availableQuantity === 0 ? '' : item.availableQuantity}
                </td>
                <td>
                     {item?.supplyDate? dayjs(item.supplyDate).format("DD/MM/YYYY") : ''}
                </td>
                
                <td>
                    {item.quotedPrice === 0 ? '' : item.quotedPrice}
                </td>
                <td>
                {item.itemStatus === '' ? (
                <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>Removed</span>
              ) : (
                  <>
                {item.itemStatus !== 'approved' && item.itemStatus !== 'rejected' && (
                  <span className='quoteRemove'
                  style={{paddingRight: '20px'}}
                    onClick={() =>{ 
                      handleItemStatus(item, actionAPI, 'approved').then(() => {
                      let updatedItems = [...localItems]
                      updatedItems[index].itemStatus = 'approved'
                      setLocalItems(updatedItems)
                      });
                  }}>
                    Approve
                  </span>
                )}
                {item.itemStatus === 'approved' && <span style={getStatusText('approved')}>Approved</span>}
                 {item.itemStatus !== 'rejected' && item.itemStatus !== 'approved' && (
                  <span className='quoteRemove' onClick={() => {
                        handleItemStatus(item, actionAPI, 'rejected').then(() => {
                            let updatedItems = [...localItems];
                            updatedItems[index].itemStatus = 'rejected';
                            setLocalItems(updatedItems);
                        });
                    }}>
                        Reject
                    </span>
                 )}
                 
                  {item.itemStatus === 'rejected' && <span style={getStatusText('rejected')}>Rejected</span>}
                  </>
              )}
                </td>
              </tr>
              : null
            ))}
            </tbody>
          </table>
        </section>
    }
    {type === 'editData' && !type2 &&
        <section className="Quotation_section">
          {!updateClicked && items.length >0 ? (
            <div>
              <div className="Quotation_subtitle">
                <span>Quotation Accepted</span>
              </div>
              <table className="Quotation_table">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Item</th>
                    <th>Available Quantity</th>
                    <th>Supply Date</th>
                    <th>Quoted Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.name}</td>
                      <td>
                        {(item?.availableQuantity === 0 ? '' : item?.availableQuantity)}
                      </td>
                      <td>
                        {item?.supplyDate ? (dayjs(item.supplyDate).isValid() ? dayjs(item.supplyDate).format("DD/MM/YYYY") : item.supplyDate) : ''}
                      </td>
                      <td>
                        {
                          (item?.quotedPrice === 0 ? '' : item?.quotedPrice)}
                      </td>
                      <td>
                      {
                        <div style={{display: 'flex', gap: '21px' , justifyContent: 'flex-end'}}>
                          <span 
                            className={`quoteRemove ${(item?.itemStatus === 'approved' || item?.itemStatus === 'pending') ? 'disabled' : ''}`}
                            onClick={(item?.itemStatus !== 'approved' && item?.itemStatus !== 'pending') ? () => handleUpdateClick(index) : null}>
                            Update
                          </span>
                          <span 
                            className={`quoteRemove ${(item?.itemStatus === 'approved' || item?.itemStatus === 'pending') ? 'disabled' : ''}`}
                            onClick={(item?.itemStatus !== 'approved' && item?.itemStatus !== 'pending') ? () => handleDeleteForItem(item?.itemRef) : null}>
                            Remove
                          </span>
                        </div>
                      }

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            items?.length > 0 && (
              <div>
                <div className="Quotation_subtitle">
                  <span>Quotation Submitted</span>
                  <span onClick={handleSaveAllClick}>Update all</span>
                </div>
                {items.map((item, index) => (
                  <div className={activeIndex == index? "active-input Quotation_eachFormRow": "Quotation_eachFormRow"} key={index} >
                    <div className="Quotation_eachForm">
                      <label>Item</label>
                      <Input
                        placeholder="Type here..."
                        value={item.name}
                        disabled={true}
                        onChange={handleInputChange(
                          index,
                          "name",
                          "item"
                        )}
                      />
                    </div>
                    <div className="Quotation_eachForm">
                      <label>Available Quantity</label>
                      <Input
                        placeholder="Type here..."
                        autoFocus={index === activeIndex}
                        disabled={item.itemStatus === 'approved'}
                        value={
                            item.availableQuantity === 0 
                            ? '' 
                            : item.availableQuantity
                        }
                        onChange={handleInputChange(
                          index,
                          "availableQuantity",
                          "item"
                        )}
                        style={{ borderColor: emptyFields.items.includes(index) && !item.availableQuantity ? '#FF4D4F' : '' }}
                      />
                    </div>
                    <div className="Quotation_eachForm">
                      <label>Supply Date</label>
                      <DatePicker
                        value={parseDate(item.supplyDate)}
                        disabled={item.itemStatus === 'approved'}
                        onChange={handleDateChange(index, "supplyDate", "item")}
                        style={{ borderColor: emptyFields.items.includes(index) && !item.supplyDate ? '#FF4D4F' : '' }}
                      />
                    </div>
                    <div className="Quotation_eachForm">
                      <label>Quoted Price</label>
                      <Input
                        placeholder="Type here..."
                        disabled={item.itemStatus === 'approved'}
                        value={
                            item.quotedPrice === 0 
                            ? ''
                            : item.quotedPrice
                        }
                        onChange={handleInputChange(
                          index,
                          "quotedPrice",
                          "item"
                        )}
                        style={{ borderColor: emptyFields.items.includes(index) && !item.quotedPrice ? '#FF4D4F' : '' }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </section>
    }
    {removedItems?.length > 0 && (
      <section className="Quotation_section">
        <div className="Quotation_subtitle">
          <span>Quotation Removed</span>
          <span onClick={handleRestoreClick}>Save all</span>
        </div>
        {removedItems?.map((item, index) => (
          <div className="Quotation_eachFormRow" key={index}>
            <div className="Quotation_eachForm">
              <label>Item</label>
              <Input
                placeholder="Type here..."
                value={item.name}
                disabled={true}
                onChange={handleInputChange(index, "name", "removeItem")}
              />
            </div>
            <div className="Quotation_eachForm">
              <label>Available Quantity</label>
              <Input
                placeholder="Type here..."
                value={
                  item.availableQuantity === 0 
                  ? '' 
                  : item.availableQuantity}
                onChange={handleInputChange(index, "availableQuantity", "removeItem")}
                style={{ borderColor: emptyFields.removedItems.includes(index) && !item.availableQuantity ? '#FF4D4F' : '' }}
              />
            </div>
            <div className="Quotation_eachForm">
              <label>Supply Date</label>
              <DatePicker
                value={parseDate(item.supplyDate)}
                onChange={handleDateChange(index, "supplyDate", "removeItem")}
                style={{ borderColor: emptyFields.removedItems.includes(index) && !item.supplyDate ? '#FF4D4F' : '' }}
              />
            </div>
            <div className="Quotation_eachForm">
              <label>Quoted Price</label>
              <Input
                placeholder="Type here..."
                value={ 
                  item.quotedPrice === 0 
                  ? ''
                  : item.quotedPrice}
                onChange={handleInputChange(index, "quotedPrice", "removeItem")}
                style={{ borderColor: emptyFields.removedItems.includes(index) && !item.quotedPrice ? '#FF4D4F' : '' }}
              />
            </div>
          </div>
        ))}
      </section>
    )}             
    </>
  )
}
