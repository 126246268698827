import { UploadOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Modal, Upload } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function EditCompanyNameModal({
  isVisible,
  onClose,
  handleSubmit,
  handleUploadChange,
}) {

  const userData = useSelector((state) => state.user); 

  const [orgName, setOrgName] = useState(_.get(userData, 'orgName', ''));
  const [uploadedFile, setUploadedFile] = useState(null);
  
  const handleUpload = (file) => {
    setUploadedFile(file)
  }

  const isOrgNameEmpty = orgName.trim() === '';
  const isOrgNameAvailable = Boolean(_.get(userData, 'orgName', ''));

  return (
    <Modal
      title="Brand Details"
      open={isVisible}
      onCancel={onClose}
      footer={null} 
      style={{ display: "flex", justifyContent: "center" }}
      className="Dashboard_modal edit"
    >
      <div className="Dashboard_modalContent">
        <div className="Dashboard_titleSection" style={{padding: '24px 0'}}>
          <p>Logo</p>
          <Avatar src={_.get(userData, 'orgPic', '') ? userData?.orgPic : null}>
            Add an icon 
          </Avatar>
            <div className="Dashboard_modalIcon" style={userData?.orgType === 'vendor' ? { backgroundColor: '#008065' } : {}}>
              <Upload
                name="file"
                beforeUpload={handleUpload}
                customRequest={() => handleUploadChange(uploadedFile)} 
                showUploadList={false}
                >
                <UploadOutlined />
              </Upload>
            </div>
        </div>
        <div className="Dashboard_titleSection">
          <p>Name</p>
          <div className="Dashboard_modalInput">
            <Input
              placeholder="Type your team/business name..."
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
        <Button key="done" type="primary" style={userData?.orgType === 'vendor' ? { backgroundColor: '#008065', border: '1px solid #008065' } : {}} disabled={isOrgNameEmpty}  className={isOrgNameEmpty ? "disabledButton" : "enabledButton"}  onClick={() => handleSubmit(orgName)}>
          {isOrgNameAvailable ? "Update" : "Save"}
        </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
