import {  BASE_URL } from '../constants/index';

export const uploadFile = async (ENDPOINT_URL, formdata) => {
    try {

        const response = await fetch(`${BASE_URL}${ENDPOINT_URL}`, {
          method: 'POST',
          body: formdata,
          credentials: 'include'
        })
        const data = await response.json();
        return { status: response.status, ...data };
    } catch (error) {
        console.error('Error in POST request:', error);
    }
  };