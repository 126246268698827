import React, { useState } from "react";
import { List } from "antd";
import { useNavigate } from "react-router-dom";
import ListInfoComponent from "../../common/ListInfoComponent";
import { COMPONENT_ROUTE_MAP } from "../../../constants";
import ModalQuotesComponent from "../vendor-list/ModalQuotesComponent";

export default function ListQuotes({ vendorData, AvatarColor, setTrigger }) {
  const navigate = useNavigate();
  const [askQuoteModal, setAskQuoteModal] = useState(false)
  const [selectedQuote, setSelectedQuote] = useState(null);

  const handleNavigateToQuote = (quoteID) => {
    navigate(`/home/quotations/vendors?quoteID=${quoteID}`); 
  };

  const handleBoxClick = (quoteId, type) => {
    setSelectedQuote({quoteId, type});
    setAskQuoteModal(true);
  };

  const handleModalClose = () => {
    setAskQuoteModal(false);
    setSelectedQuote(null);
  };

  return (
    <section className="ListComponent_container" style={{ width: "100%" }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={vendorData}
        renderItem={(item) => (
          <List.Item>
            <div className="ListComponent_box2">
              <ListInfoComponent
                name={item.title}
                id={item.quoteId}
                AvatarColor={AvatarColor}
                type={'ListQuotes'}
                item={item}
                handleIconClick={handleNavigateToQuote}
                handleBoxClick={handleBoxClick}
              />
            </div>
          </List.Item>
        )}
      />
      {selectedQuote && (
        <ModalQuotesComponent
          isVisible={askQuoteModal}
          onClose={handleModalClose}
          type={selectedQuote.type}
          quoteID={selectedQuote.quoteId}
          setTrigger={setTrigger}
        />
      )}
    </section>
  );
}
