import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, message } from 'antd';
import _ from 'lodash'
import { MODAL_TITLES, LINK_SEPERATOR } from '../../constants';
import { DeleteFilled, PlusSquareOutlined } from '@ant-design/icons';

const ModalDialogComponent = ({type, isVisible, onCancel, modalData, handleModalAction }) => {
  
  const title = _.get(MODAL_TITLES[type], ((modalData && modalData.noteAction) || '').toUpperCase(), '')

  const [editing, setEditing] = useState(true);
  const [inputText, setInputText] = useState('');
  const [inputUrls, setInputUrls] = useState(['']);

  useEffect(() => {
    if (!_.isEmpty(modalData)) {
      const [rawText='', rawUrls=''] = modalData.noteValue.split(LINK_SEPERATOR);
      const urls = rawUrls.split('\n').filter(url => url.trim().length > 0);
      setInputText(rawText); 
      setInputUrls(urls.length > 0 ? urls : ['']);
      if (modalData.noteAction === 'delete') {
        setEditing(false);
      }
    }else{
      setEditing(true);
    }
  }, [modalData, setInputText]);

  const handleButtonClick = () => {
    if (inputText.trim() === '') {
      message.error({
        content: 'Input field is empty. Please write something before saving.',
        duration: 3,
        style: {
          position: 'fixed',
          transform: 'translateX(-50%)',
          left: '44%',
          zIndex: 1000,
        },
      });
      return;
    }
    const mergedNoteValue = [inputText, inputUrls.join(' \n')].join('\n Link:- \n');
    handleModalAction({ ...modalData, noteValue: mergedNoteValue });
  }

  const addUrlInput = () => {
    if (inputUrls.length < 3) {
      setInputUrls((prevState) => [...prevState, '']);
    }
  };

  const handleUrlInputChange = (index, newValue) => {
    const updatedUrlInputs = [...inputUrls];
    updatedUrlInputs[index] = [ newValue ];
    setInputUrls(updatedUrlInputs);
  };

  const handleDeleteUrlInput = (index) => {
    const updatedUrlInputs = [...inputUrls];
    updatedUrlInputs.splice(index, 1);
    setInputUrls(updatedUrlInputs);
  };

  return (
    <Modal
      className='ProfileView_notesModal'
      title={title}
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      style={{ maxWidth: '520px' }}>
      <div className='ProfileView_notesModalInput'>
      <Input.TextArea  placeholder="Type here" value={inputText} onChange={(e) => setInputText(e.target.value)} disabled={!editing} autoSize={{ minRows: 4, maxRows: 20 }}  />
      {inputUrls.map((urlInput, index) => (
          <Input key={index} className={`ProfileView_notesModalInput-2 hover-icon-${index}`} rows={1} placeholder="Paste URL here"  value={urlInput} onChange={(e) => handleUrlInputChange(index, e.target.value)} disabled={!editing}
          suffix={
            editing && <DeleteFilled onClick={() => handleDeleteUrlInput(index)}  />
          }
         />
      ))}
      <div className={`ProfileView_notesModalInput-2_detail ${inputUrls.length > 3 && 'block'} `}>
      {inputUrls.length < 3 && editing && (<span onClick={addUrlInput}><PlusSquareOutlined /> Add new</span>)}
        <span>You can paste upto 3 URLs</span>
      </div>
      </div>
      <div style={{ textAlign: 'right', marginTop: '16px' }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type= 'primary'
          danger={!editing}
          style={{ marginLeft: '10px' }}
          onClick={handleButtonClick}
        >
          {editing ? 'Save' : 'Delete'}
        </Button>
      </div>
    </Modal>
  );
};


export default ModalDialogComponent;
