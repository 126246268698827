import React from "react";
import { Button } from "antd";
import "./StatusTemplate.css";
import { showErrorMessage } from "../../utils";

export default function StatusTemplate({data}) {
  const { title, subText, buttonLink, buttonText, imageSrc, supportRequestLink,supportText } = data;


  const handleButtonClick = () => {
    switch (typeof buttonLink) {
      case 'function':
        buttonLink();
        break;
      case 'string':
        window.location.href = buttonLink;
        break;
      default:
        showErrorMessage('Unsupported link')
        break;
    }
  };
  return (
    <main className="Et_container">
      <div className="Et_subContainer">
        <div className="Et_textContainer">
          <div className="Et_text">
            <h1>{title}</h1>
            <div className="Et_subText">
            {subText.map((text, index) => (
                <span key={index}>{text}</span>
              ))}
              <span>
                <a href={supportRequestLink}>{supportText}</a>
              </span>
            </div>
          </div>
          <Button onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </div>
        <img src={imageSrc} alt="" />
      </div>
    </main>
  );
}
