import React from 'react';
import { Breadcrumb } from 'antd';

const BreadcrumbComponent = ({ selectedParent, selectedChild, additionalText = '' }) => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        {selectedParent && <Breadcrumb.Item>{selectedParent}</Breadcrumb.Item>}
        {selectedChild && <Breadcrumb.Item>{selectedChild}</Breadcrumb.Item>}
        {additionalText && <Breadcrumb.Item>{additionalText}</Breadcrumb.Item>}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComponent;
