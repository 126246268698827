import {  BASE_URL } from "../constants";

const SERVICE_NAME = 'activities';

export async function createNote(noteReqBody) {
    try {
        const apiUrl = `${BASE_URL}/${SERVICE_NAME}/`;
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(noteReqBody),
        };

        const response = await restAPIHandler(apiUrl, options)
        if (response.success) return response

        throw new Error('Failed to create note');
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

export async function updateNote(updatedNote) {
    try {
        const apiUrl = `${BASE_URL}/${SERVICE_NAME}/${updatedNote.id}`;
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({ value: updatedNote.actionValue }),
        };

        const response = await restAPIHandler(apiUrl, options)
        if (response.success) return response

        throw new Error('Failed to update note');
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

export async function deleteNote(noteId) {
    try {
        const apiUrl = `${BASE_URL}/${SERVICE_NAME}/${noteId}`;
        const options = {
            method: 'DELETE',
            credentials: 'include'
        };

        const response = await restAPIHandler(apiUrl, options)
        if (response.success) return response

        throw new Error('Failed to delete note');
    } catch (error) {
        console.error('API error:', error);
        throw error;
    }
}

const restAPIHandler = async (apiUrl, options) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    const requestOptions = {headers, ...options};
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();

    //TODO: remove _id check post fixing backend
    if (responseData.success || responseData._id) {
        return { success: true, id: responseData._id };
    }
    return { success: false, id: '' };
}