import { Select } from 'antd'
import React from 'react'

const { Option } = Select;

export default function OptionSelect({options, value, onSelect}) {

    const handleOptionChange = (selectedValue) => {
        onSelect(selectedValue);
    };

  return (
    <Select placeholder='Select' value={value} onChange={handleOptionChange}>
    {options.map((option) => (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    ))}
  </Select>
  )
}
