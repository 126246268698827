import React from 'react'
import { Button } from "antd";
import { BASE_IMG, HOMEPAGE_REDIRECT_ROUTE } from '../../constants';

export default function UserStepTemplates({ userStep, userType, handleSendButton }) {

    const CTA_TEMPLATES = {
      '': {
        title: "Aaah!! Some error has happened",
        subText: "We suggest you to retry login",
        buttonText: "Go To Login",
        imageSrc: userType === "vendor" ? `${BASE_IMG}/Vverify.svg` : `${BASE_IMG}/Everify.svg`, 
        linkText: "See our plans",
        linkURL:  userType === "vendor" ?`${HOMEPAGE_REDIRECT_ROUTE}/pricing/vendors` : `${HOMEPAGE_REDIRECT_ROUTE}/pricing/enterprises`
      },
     email: {
        title: "Verify your account",
        subText: "Your account needs to be verified. Make a request to",
        subText1:" send you verification link or in case already requested, please contact us!!",
        buttonText: "Send confirmation email",
        imageSrc: `${BASE_IMG}/vendorVerify.svg`,
      },
      account: {
        title: "Just one last step",
        subText: "Your account needs to be activated. Make a request to",
        subText1:" activate your account or in case already requested, please contact us!!",
        buttonText: "Send activation request",
        imageSrc: userType === "vendor" ? `${BASE_IMG}/Vverify.svg` : `${BASE_IMG}/Everify.svg`, 
        linkText: "See our plans",
        linkURL:  userType === "vendor" ?`${HOMEPAGE_REDIRECT_ROUTE}/pricing/vendors` : `${HOMEPAGE_REDIRECT_ROUTE}/pricing/enterprises`
      }
    };

  const selectedTemplate = CTA_TEMPLATES[userStep] || {};
  return (
    <div className={`Uv_subContainer ${userType === "vendor" ? "greenBG" : "blueBG"}`}>
      <div className="Uv_content">
        <img src={selectedTemplate.imageSrc} alt="" />
        <div className={`Uv_heading ${userType === "vendor" ? "greenColor" : "blueColor"}`}>
          <h1>{selectedTemplate.title}</h1>
          <div>
            <p>{selectedTemplate.subText}</p>
            <p>{selectedTemplate.subText1}</p>
          </div>
        </div>
        <div className={`Uv_button ${userType === "vendor" ? "greenColor" : "blueColor"}`}>
          <Button onClick={handleSendButton}>{selectedTemplate.buttonText}</Button>
          <a target="_blank" rel="noopener" href={selectedTemplate.linkURL}>{selectedTemplate.linkText}</a>
        </div>
      </div>
    </div>
  )
}
