import React, { useEffect, useState } from "react";
import QuoteAttachment from "../vendor-quotations/QuoteAttachment";
import dayjs from "dayjs";
import { Button, Descriptions, Modal } from "antd";
import {
  getCommentsApproval,
  getFiles,
  postCommentApproval,
} from "../vendor-quotations/quotationService";
import RequestNudgeComponent from "../../nudge/RequestNudgeComponent";
import { capitalizeFirstLetter, getStatusStyle, showErrorMessage, showSuccessMessage } from "../../../utils";
import { BASE_URL } from "../../../constants";
import QuoteNotes from "../vendor-quotations/QuoteNotes";
import { postData } from "../../../services/HomeService";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined } from "@ant-design/icons";

export default function ModalApprovalComponent({ isVisible, onClose, type, item, vendorData, setVendorData }) {
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const [attachmentID, setAttachmentID] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  
  const [fileCounts, setFileCounts] = useState({
    all: 0,
    sent: 0,
    received: 0,
  });
  const [notes, setNotes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [reason, setReason] = useState('');
  const maxCharacterCount = 50;

  useEffect(() => {
    getCommentsApproval(item?._id)
      .then((data) => setNotes(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const addNote = () => {
    const newNote = {
      text,
      timestamp: new Date(),
    };
    postCommentApproval(item?._id, newNote)
      .then((data) => {
        setText("");
        return getCommentsApproval(item?._id);
      })
      .then((data) => setNotes(data))
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const uploadProps = {
    name: "file",
    action: `${BASE_URL}/approvals/files/${item?._id}`,
    method: "POST",
    withCredentials: true,
    headers: {},
    fileList,
    onChange: (info) => {
      let fileList = [...info.fileList];
      setFileList(fileList);
      if (info.file.status === "done") {
        //clear the uplodad file from input
        setFileList([]);
        showSuccessMessage("File uploaded successfully");
        handleFiles();
      } else if (info.file.status === "error") {
        showErrorMessage("File upload failed");
      }
    },
  };

  const handleFiles = () => {
    getFiles(`/approvals/files/${item?._id}?filter=all`)
      .then((data) => {
        setFiles(data);
        const allCount = data?.all?.length ?? 0;
        const sentCount = data?.sent?.length ?? 0;
        const receivedCount = data?.received?.length ?? 0;
        setFileCounts({ all: allCount, sent: sentCount, received: receivedCount });
      })

      .catch((error) => console.error("Error:", error));
  };

  useEffect((key) => {
    handleFiles(key);
  }, []);

  const handleDelete = (file) => {
    setAttachmentID(file._id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

const handleDeleteAttachment = (id) => {
  fetch(`${BASE_URL}/activities/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((response) => {
      if (response.status === 200) {
        let newFiles;
        // Check if files is in the expected format
        if (files.hasOwnProperty('all') && files.hasOwnProperty('sent') && files.hasOwnProperty('received')) {
          newFiles = {
            all: files.all.filter((f) => f._id !== id),
            sent: files.sent.filter((f) => f._id !== id),
            received: files.received.filter((f) => f._id !== id),
          };
        } else if (Array.isArray(files)) { // Check if files is an array
          newFiles = files.filter((f) => f._id !== id);
        } else {
          console.error("Unexpected files format");
          // return;
        }
        setFiles(newFiles);
        showSuccessMessage("File deleted successfully");
        setDeleteModal(false);
      } else {
        showErrorMessage("File deletion failed");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

  const handleAction = async (actionAPI, type, reason = '') => {
    let action ;
    const actions = actionAPI.actions || [];
    if(type == 'approve'){
      action = actions.find(action => action.name === 'Approve');
    }
    else if(type == 'reject'){
      action = actions.find(action => action.name === 'Reject');
    }
    else{
      action = actions.find(action => action.name === 'Partial Approve');
    }
    let requestedData = {
      items: actionAPI.items
    };
    if (type !== 'approve') {
        requestedData = {
            ...requestedData,
            reason: reason,
        };
    }
    const data = await postData(action.apiEndpoint, requestedData);
    if (data) {
        const messageContent = type === 'approve' ? 'Approved' : 'Rejected';
        showSuccessMessage(messageContent);

        // Update vendorData based on entityId
        const updatedVendorData = vendorData.map(item => {
            if (item.entityId === data._id) {
                return {
                    ...item,
                    finalStatus: data.status
                };
            }
            return item;
        });

        setVendorData(updatedVendorData);
        onClose();
    } else {
        showErrorMessage('Something went wrong');
    }
};
  return (
    <Modal
    open={isVisible}
    onCancel={onClose}
    title={'Approval request'}
    footer={null}
    className="Quotes_modal Modal_qt"
  >
    <div className="Quotes_container">
      <div className="Quotation_firstContainer">
        <div className="Quotation_subContainer">
          <span>
            Dated: <span>{dayjs(item?.createdAt).format("DD/MM/YYYY")}</span>
          </span>
          <span>
            From: <span>{item?.userMeta.name}</span>
          </span>
          <span>
            Raze ID{' '} 
            <span className="Quotation_copy">
              <CopyOutlined/>
            </span>
          </span>
          <div className="ListComponent_qs">
            <i style={getStatusStyle(item?.finalStatus)} />
            <span>{capitalizeFirstLetter(item?.finalStatus)}</span>
          </div>
        </div>
      </div>

      <section className="approvals_title">
        <h3>{item?.requestMeta?.title + ' '}<span>{item?.requestMeta?.quoteId}</span></h3>
        <span>{item?.requestMeta?.description}</span>
      </section>
      <QuoteAttachment 
        files={files}
        handleDelete={handleDelete}
        handleFiles={handleFiles}
        fileCounts={fileCounts}
        type2={'ModalApprovals'}
        />

        <QuoteNotes
         type={'ListApprovals'}
          notes={notes}
          text={text}
          setText={setText}
          uploadProps={uploadProps}
          addNote={addNote}
          />
    </div>
    {type === 'reject' &&
        <div className='Approval_Reject' style={{padding: '0 24px 24px 24px'}}>
        <div className="Quotation_subtitle">Reason for rejection</div>
            <TextArea
                rows={3}
                placeholder="Type here"
                value={reason}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= maxCharacterCount) {
                        setReason(inputValue);
                    }
                }}
            />
            <div className='Approval_Reject_Mend'>
                <span><span style={{ color: '#FF4D4F' }}>*</span>This is a mandatory field</span>
                <span>{reason.length}/{maxCharacterCount}</span>
            </div>
        </div>
    }
    <div className="Quotes_footer">
        {type === 'approve' ?
            <Button className="approvals_btn" onClick={() =>handleAction(item, type)}>
                Approve
            </Button>
        : type === 'reject' ?
            <Button className="reject_btn" onClick={() => handleAction(item, type, reason)} disabled={reason.length === 0 || reason.length > maxCharacterCount} >
                Reject
            </Button>
        :   <Button className="reject_btn" onClick={() => handleAction(item, type, reason)} >
              Send Reminder
            </Button>
           } 

    </div>

    <RequestNudgeComponent
        type={'deleteAttachment'}
        data={attachmentID}
        isVisible={deleteModal}
        onClose={closeDeleteModal}
        handleNudgeSubmit={handleDeleteAttachment}
      />
  </Modal>
  );
}
