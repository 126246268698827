import React, { useEffect, useCallback, useState } from 'react';
import { Menu, Layout, Collapse } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import './SiderComponent.css';
import { Link, useLocation } from 'react-router-dom';
import { DEFAULT_REDIRECT_ROUTE } from '../../constants/index';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

const SiderComponent = ({ items, getIcon, handleCollapse, collapsed, onMenuItemSelect }) => {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const userData = useSelector((state) => state.user);

    useEffect(() => {
        if (items && items.length > 0) {
            const newSelectedKeys = [];
            const newOpenKeys = [];

            items.forEach((item) => {
                if (item.children && item.children.length > 0) {
                    item.children.forEach((child) => {
                        if (location.pathname === child.linkURL) {
                            newSelectedKeys.push(child.key);
                            newOpenKeys.push(item.key);
                        }
                    });
                } else {
                    if (location.pathname === item.linkURL) {
                        newSelectedKeys.push(item.key);
                    }
                }
            });
            setSelectedKeys(newSelectedKeys);
            setOpenKeys(newOpenKeys);
        }
    }, [items, location.pathname]);
    
    const toggleCollapse = () => {
        handleCollapse(!collapsed);
    };

    const handleMenuItemSelect = useCallback((childLabel, parentLabel) => {
        onMenuItemSelect(childLabel, parentLabel);
    }, [onMenuItemSelect]);

    useEffect(() => {
        //TODO: We need to fix this hack by introducing redux and adding on click events handlers
        if (location.pathname !== DEFAULT_REDIRECT_ROUTE && items.length > 0) {
            for (const item of items) {
                const matchedItem = item.children && item.children.find(ch => ch.linkURL === location.pathname);
                if (matchedItem) {
                    handleMenuItemSelect(matchedItem.label, item.label);
                    break;
                }
            }
        }
    }, [location.pathname, items, handleMenuItemSelect]);

    const getMenuItems = (item) => {
        if (item.linkURL) {
            if (item.linkType === 'internal') {
                return (
                    <Menu.Item key={item.key} icon={getIcon && getIcon(item.icon)} className={userData?.orgType === 'vendor' && 'sidebar-menu'}>
                        <Link to={item.linkURL}>{item.label}</Link>
                    </Menu.Item>
                );
            } else {
                return (
                    <Menu.Item key={item.key} icon={getIcon && getIcon(item.icon)} className={userData?.orgType === 'vendor' && 'sidebar-menu'}>
                        <Link to={item.linkURL} target="_blank" rel="noopener noreferrer">
                            {item.label}
                        </Link>
                    </Menu.Item>
                );
            }
        } else {
            return (
                <Menu.Item key={item.key} className={userData?.orgType === 'vendor' && 'sidebar-menu'}>
                    <Link to={DEFAULT_REDIRECT_ROUTE}>{item.label}</Link>
                </Menu.Item>
            );
        }
    };
    return (
        <Sider className={`${userData?.orgType === 'vendor' && 'sidebar-sider'} ${collapsed ? 'collapsed' : ''}`} collapsible collapsed={collapsed} onCollapse={toggleCollapse} style={{ backgroundColor: '#fff' }}>
            <div className="sidebar-container">
                <div className="sidebar-toggle">
                    <Collapse>
                        {collapsed ? (
                            <MenuUnfoldOutlined onClick={toggleCollapse} />
                        ) : (
                            <MenuFoldOutlined onClick={toggleCollapse} />
                        )}
                    </Collapse>
                </div>
            </div>
            <Menu
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onSelect={(item) => setSelectedKeys(item.key)} 
                onOpenChange={(keys) => setOpenKeys(keys)}
                mode="inline"
                style={{ top: '86px', position: 'relative' }}
            >
                {items.map((item) => {
                    if (item.children) {
                        return (
                            <Menu.SubMenu
                                key={item.key}
                                icon={getIcon && getIcon(item.icon)}
                                title={item.label}
                            >
                                {item.children.map((child) => {
                                    return getMenuItems(child, item.label);
                                })}
                            </Menu.SubMenu>
                        );
                    } else {
                        return getMenuItems(item);
                    }
                })}
            </Menu>
        </Sider>
    );
};

export default SiderComponent;
