import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import './style.css';
import { fetchData } from '../../../services/HomeService';
import { ArrowLeftOutlined, CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import { BASE_IMG } from '../../../constants';
import VendorQuoteComponent from './VendorQuoteComponent';
import { useLocation, useNavigate } from 'react-router-dom';

export default function VendorListQuotes({ selectedParent, selectedChild, AvatarColor }) {
    const [vendorData, setVendorData] = useState([]);
    const [quoteData, setQuoteData] = useState([]);
    const [sorting, setSorting] = useState({ field: 'updatedAt', order: 'desc' });

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const quoteID = searchParams.get('quoteID');

    // TODO: Remove this hard coded values 
    // Solution1- Fetch with presistent storage
    const _selectedParent = 'Dashboard';
    const _selectedChild  = 'Quotations';
    const additionalText = `Quotation Id ${quoteID}`;

    const fetchItems = async () => {
        const queryString = `/quotations/quote/${quoteID}?sort=${sorting.field}-${sorting.order}`;
        const data = await fetchData(queryString);
        setVendorData(data.data);
        setQuoteData(data.quoteDetails);
    };

    useEffect(() => {
        fetchItems();
    }, [sorting.field, sorting.order]);


    const handleSortChange = (field, order='asc') => {
        setSorting({ field, order });
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <main className="Home_container">
            <section className="Home_firstContainer">
            <BreadcrumbComponent  selectedParent={selectedParent || _selectedParent} selectedChild={selectedChild || _selectedChild} additionalText={additionalText} />
                <div className="Home_heading">
                <ArrowLeftOutlined className='VendorOnboarding_arrowBack' onClick={handleGoBack} />
                <h1>{quoteData?.title}<span> {quoteID}</span></h1>
                </div>
            </section>
            <section className="VendorLists_adding">
            <section className="ListComponent_sorter">
          <div className="ListComponent_name" style={{ width: "218px" }}>
            <p>Vendor</p>
            <div className="ListComponent_sorterIcon">
              <CaretUpOutlined onClick={() => handleSortChange("title", "asc")}/>
              <CaretDownOutlined onClick={() => handleSortChange("title", "desc")}/>
            </div>
          </div>

          <div style={{width: '173px', display: "flex",justifyContent: "center"}} >
            <p style={{ paddingRight: "8px" }}>Status</p>{" "}
            <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/category.svg`} alt=""/>
          </div>

          
          <div style={{width: '134px', display: "flex",justifyContent: "center"}} >
            <p style={{ paddingRight: "8px" }}>Quoted price</p>{" "}
            <img src={`${BASE_IMG}/dashboard-img/dashboard_icons/category.svg`} alt=""/>
          </div>
          <p style={{width: '308px'}}>Latest Notes</p>
          <p style={{width: '210px',justifyContent: 'flex-end',paddingRight: '52px'}}>Action</p>
        </section>
                <VendorQuoteComponent quoteData= {quoteData} vendorData={vendorData} setQuoteData={setQuoteData} setVendorData={setVendorData} AvatarColor={AvatarColor}/>
            </section>
        </main>
    );
}
